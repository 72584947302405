import React from "react";
import { Route, Switch } from "react-router-dom";
// Templates
import TemplateNothing from "../Layout/TemplateNothing";
// Routes
import { Login } from "../pages/Login";
import SignUpComponent from "../pages/Signup";
import ForgotPasswordComponent from "../pages/ForgotPassword";

import IndexComponent from "../pages/Initialize";
//exception
import Exception from "../Common/Exception";
import InactiveProfileException from "../Common/InactiveProfile";
import AuthorizedRoute from "../Common/Authorization/AuthorizedRoutes";
import Profile from "../pages/Profile/Index";

import {
  LoginActions,
  LogoutActions
} from "../constants/ApiAuthorizationConstants";
import { Logout } from "../pages/Logout";
import TemplateNavbar from "../Layout/TemplateNavbar";
import SystemUsersList from "../pages/Users/SystemUser";
import AddCompanyUser from "../pages/Users/AddCompanyUser";
import CreateCompany from "../pages/Companies/CreateCompany";
import AddSystemUser from "../pages/Users/AddSystemUser";
import { ChangePasswordPage } from "../pages/Users/ChangePassword";
import FindBroker from "../pages/Users/FindBroker";
import FindLender from "../pages/Users/FindLender";
import { BrokerProfile } from "../pages/Profile/Broker";
import Notes from "../pages/Users/Notes";
import LoansDetailsView from "../pages/Loans/LoansDetailsView";
import LoansCreate from "../pages/Loans/Create";
import { FinderUtilityView } from "../pages/FinderUtility";
import { PrivacySettingsView } from "../pages/PrivacySettings";
import { EmailSubscriptionsView } from "../pages/EmailSubscriptions";
import LoansView from "../pages/Loans/View";
import LawFirmProfile from "../pages/Profile/LawFirm";
import CreateEmail from "../pages/Marketing/CreateEmail";
import AllUsers from "../pages/Users/AllUsers";
import LoansSnapshotView from "../pages/Loans/LoansSnapshotView";
import { LAW_FIRM_USER } from "../constants/systemUsers";
import EmailSubscriptionsPublic from "../pages/EmailSubscriptions/EmailSubscriptionsPublic";

let id = 0;

/** @type {{id: number, name: string, index: string, auth: string, layout: JSX.Element, page: JSX.Element, path: string}[]} */
const defaultRoutes = [
  {
    id: id++,
    name: "users",
    index: "users",
    layout: TemplateNavbar,
    page: SystemUsersList,
    path: "/users"
  },
  {
    id: id++,
    name: "users",
    index: "users",
    layout: TemplateNavbar,
    page: Profile,
    path: "/users/:id/lender",
    profileType: "lender"
  },
  {
    id: id++,
    name: "users",
    index: "users",
    layout: TemplateNavbar,
    page: BrokerProfile,
    path: "/users/:id/broker",
    profileType: "broker"
  },
  {
    id: id++,
    name: "users",
    index: "users",
    layout: TemplateNavbar,
    page: LawFirmProfile,
    path: "/users/:id/lawfirm",
    profileType: "lawfirm"
  },
  {
    id: id++,
    name: "users",
    index: "users",
    layout: TemplateNavbar,
    page: AddCompanyUser,
    path: "/companies/:id/users/create"
  },
  {
    id: id++,
    name: "users",
    index: "users",
    layout: TemplateNavbar,
    page: AddSystemUser,
    path: "/systemUsers/create"
  },
  {
    id: id++,
    name: "Change password",
    index: "change-password",
    layout: TemplateNavbar,
    page: ChangePasswordPage,
    path: "/change-password"
  },
  {
    id: id++,
    name: "Search Lender",
    index: "search-lender",
    layout: TemplateNavbar,
    page: FindLender,
    path: "/search/lender"
  },
  {
    id: id++,
    name: "Search Broker",
    index: "search-broker",
    layout: TemplateNavbar,
    page: FindBroker,
    path: "/search/broker"
  },
  {
    id: id++,
    name: "Notes",
    index: "individual-notes",
    layout: TemplateNavbar,
    page: Notes,
    path: "/users/notes"
  },

  {
    id: id++,
    name: "MonthlyScores",
    index: "monthly-scores",
    layout: TemplateNavbar,
    page: Notes,
    path: "/users/notes"
  },
  {
    id: id++,
    name: "LoansDetailsViews",
    index: "loans-details-view",
    layout: TemplateNavbar,
    page: LoansDetailsView,
    path: "/loans/:id/view"
  },
  {
    id: id++,
    name: "LoansSnapshotDetailsViews",
    index: "loans-snapshot-view",
    layout: TemplateNavbar,
    page: LoansSnapshotView,
    path: "/loans/:id/logs/:logId"
  },
  {
    id: id++,
    name: "LoansCreateViews",
    index: "loans-create-view",
    layout: TemplateNavbar,
    page: LoansCreate,
    path: "/loans/create"
  },
  {
    id: id++,
    name: "LoansEditView",
    index: "loans-edit-view",
    layout: TemplateNavbar,
    page: LoansCreate,
    path: "/loans/:id/edit"
  },
  {
    id: id++,
    name: "LoansView",
    index: "loans",
    layout: TemplateNavbar,
    page: LoansView,
    path: "/loans"
  },
  {
    id: id++,
    name: "FinderUtilityView",
    index: "finder-utility",
    layout: TemplateNavbar,
    page: FinderUtilityView,
    path: "/finder-utility"
  },
  {
    id: id++,
    name: "PrivacySettingsView",
    index: "privacy-settings",
    layout: TemplateNavbar,
    page: PrivacySettingsView,
    path: "/privacy-settings/:id"
  },
  {
    id: id++,
    name: "EmailSubscriptionsView",
    index: "email-subscriptions",
    layout: TemplateNavbar,
    page: EmailSubscriptionsView,
    path: "/email-subscriptions/:id"
  },
  {
    id: id++,
    name: "EmailSubscriptionsView",
    index: "email-subscriptions",
    layout: TemplateNavbar,
    page: EmailSubscriptionsView,
    path: "/email-subscriptions/:id/lawfirm",
    profileType: LAW_FIRM_USER
  },
  {
    id: id++,
    name: "companies",
    index: "companies",
    layout: TemplateNavbar,
    page: CreateCompany,
    path: "/companies/create"
  },
  {
    id: id++,
    name: "bulk-email",
    index: "users",
    layout: TemplateNavbar,
    page: CreateEmail,
    path: "/bulk-email",
    generalView: true
  },
  {
    id: id++,
    name: "all-users",
    index: "users",
    layout: TemplateNavbar,
    page: AllUsers,
    path: "/users/all"
  },
];

const Index = () => (
  <Switch>
    {defaultRoutes.map((View, key) => {
      return (
        <Route
          exact
          key={View.id}
          path={View.path}
          render={props => (
            <AuthorizedRoute {...props} key={View.id}>
              <View.layout
                index={View.index}
                {...props}
                profileType={View.profileType}
                generalView={View.generalView || false}
              >
                <View.page {...props} profileType={View.profileType} />
              </View.layout>
            </AuthorizedRoute>
          )}
        />
      );
    })}

    <Route
      path="/login"
      render={props => (
        <TemplateNothing>
          <Login action={LoginActions.Login} {...props} />
        </TemplateNothing>
      )}
    />
    <Route
      path="/confirm-login"
      render={props => (
        <TemplateNothing>
          <Login action={LoginActions.LoginCallback} {...props} />
        </TemplateNothing>
      )}
    />
    <Route
      path="/logout"
      render={props => (
        <TemplateNothing>
          <Logout action={LogoutActions.Logout} {...props} />
        </TemplateNothing>
      )}
    />
    <Route
      path="/confirm-logout"
      render={props => (
        <TemplateNothing>
          <Logout action={LogoutActions.LogoutCallback} {...props} />
        </TemplateNothing>
      )}
    />
    <Route
      path="/signUp"
      render={props => (
        <TemplateNothing>
          <SignUpComponent {...props} />
        </TemplateNothing>
      )}
    />

    <Route
      path="/forgotPassword"
      render={props => (
        <TemplateNothing>
          <ForgotPasswordComponent {...props} />
        </TemplateNothing>
      )}
    />

    <Route
      exact
      path="/general/finder-utility"
      render={props => (
        <TemplateNavbar generalView>
          <FinderUtilityView {...props} generalView />
        </TemplateNavbar>
      )}
    />

    <Route
      path="/public/brokers/:name/:id"
      render={props => (
        <TemplateNavbar generalView>
          <BrokerProfile {...props} generalView />
        </TemplateNavbar>
      )}
    />

    <Route
      path="/general/brokers/:id"
      render={props => (
        <TemplateNavbar generalView>
          <BrokerProfile {...props} generalView />
        </TemplateNavbar>
      )}
    />

    <Route
      path="/general/loans/:id"
      render={props => (
        <TemplateNavbar generalView>
          <LoansDetailsView {...props} generalView />
        </TemplateNavbar>
      )}
    />

    <Route
      path="/general/email-subscriptions/:token"
      render={props => (
        <TemplateNavbar generalView>
          <EmailSubscriptionsPublic {...props} />
        </TemplateNavbar>
      )}
    />

    <Route
      path="/exception/403/inactive-profile"
      exact
      render={props => <InactiveProfileException {...props} />}
    />
    
    <Route path="/exception" exact render={props => <Exception {...props} />} />
    <Route
      path="/exception/:type"
      exact
      render={props => <Exception {...props} />}
    />
    <Route path="/" render={props => <IndexComponent {...props} />} />
  </Switch>
);

export default Index;

import { Card, Form, Radio } from "antd";
import React from "react";

const MarketingEmails = ({
  getFieldDecorator,
  canEdit
}) => {
  return (
    <Card className="user-subscription m-b-24 card-padding-12 position-relative flex flex-center">
        <div className="title-center card-title">Marketing Emails</div>
        {getFieldDecorator("marketingEmails")(
            <Radio.Group disabled={!canEdit}>
              <Radio value={true}>Subscribed</Radio>
              <Radio value={false}>Unsubscribed</Radio>
            </Radio.Group>
          )}
    </Card>
  );
};
export default MarketingEmails;

import { Button, Col, Icon, Modal, Row } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { mustBeArray } from '../../libs';

const OrderOfAppearance = ({portraits, companyName, handleFormSave}) => {
    const [visible, setVisible] = useState(false);
    const [currentPortraits, setCurrentPortraits] = useState([]);
    const title = `Order Users ${companyName ? `for ${companyName}` : ""}`
    useEffect(() => {
        if(visible){
            setCurrentPortraits(mustBeArray(portraits));
        }
    }, [portraits, visible]);

    const handleCancel = () => {
        setVisible(false);
        setCurrentPortraits([]);
    };

    const handleUp = (index) => {
        let tempPortraits = [...currentPortraits];
        const selectedPortrait = tempPortraits[index];
        tempPortraits.splice(index, 1);
        tempPortraits.splice(index - 1, 0, selectedPortrait);
        setCurrentPortraits(tempPortraits);
    };

    const handleDown = (index) => {
        let tempPortraits = [...currentPortraits];
        const selectedPortrait = tempPortraits[index];
        tempPortraits.splice(index, 1);
        tempPortraits.splice(index + 1, 0, selectedPortrait);
        setCurrentPortraits(tempPortraits);
    };

    const handleSave = () => {
        const updatedPortraits = [...currentPortraits].map((portrait, index) => ({...portrait, appearanceOrder: index}));
        handleFormSave({label: "portraits", value: updatedPortraits});
        setVisible(false);
    };

    return <Fragment>
        <Button type="primary" onClick={() => setVisible(true)} disabled={portraits?.length <= 1}>Order of Appearance</Button>
        <Modal title={title} visible={visible} okText="Save" onOk={handleSave} onCancel={handleCancel}>
            {
                currentPortraits?.map((portrait, index) => {
                    return <Row gutter={24} key={portrait.individualId}> 
                        <Col span={4} className='text-right'>
                            {index !==0 && <Icon type="arrow-up" onClick={() => handleUp(index)} />}
                            {index !== currentPortraits.length - 1 && <Icon type="arrow-down" onClick={() => handleDown(index)} />}
                        </Col>
                        <Col span={20}>
                            {portrait.firstname} {portrait.lastname}
                        </Col>
                    </Row>
                })  
            }
        </Modal>
    </Fragment>
}
export default OrderOfAppearance;
import { Pagination, Upload, message } from "antd";
import { UserProfileImage } from "../Common";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Visible } from "../Common/visiblityWrapper";
import { getDataUrlFromBlob, mustBeArray, constructUrl } from "../../libs";
import PortraitForm from "./portrait-form";
import PortraitRemove from "./PortraitRemove";
import { updateActivePortrait } from "../../actions/profile";
import { PROFILE_HAS_NOT_ACCEPTED_INVITATION, CAN_RESET_PROFILE_PASSWORD } from "../../constants/routeOptions";
import { updateRouteOptions } from "../../actions/member";
import { SuperSEO } from "react-super-seo";

const PortraitView = ({
  portraits = [],
  form,
  handleFormChange,
  currentPage,
  setCurrent,
  setCurrentPortrait,
  isSystemUser,
  company = {
    companyACN: "",
    companyName: "",
    website: ""
  },
  portraitsUpdated,
  handleRemove
}) => {
  const dispatchAction = useDispatch();
  const routesOptions = useSelector(state => mustBeArray(state.member?.routesOptions));
  const [user, setUser] = useState({});
  const editMode = useSelector(state => state?.member?.header?.editMode);

  useEffect(() => {
    const index = currentPage ? currentPage - 1 : 0;
    setUser(portraits?.[index] ? portraits[index] : {});
  }, [currentPage, portraitsUpdated]);

  useEffect(() => {
    dispatchAction(updateActivePortrait(user));
    // Check if user has accepted invitiation,
    if(user.hasAcceptedInvitation){
      const updatedOptions = [...routesOptions.filter(o => o.id != CAN_RESET_PROFILE_PASSWORD[0].id && o.id != PROFILE_HAS_NOT_ACCEPTED_INVITATION[0].id), CAN_RESET_PROFILE_PASSWORD[0]];
        dispatchAction(
          updateRouteOptions({
            routeOptions: updatedOptions
          })
        );
      return;
    }
    else {
      const updatedOptions = [...routesOptions.filter(o => o.id != CAN_RESET_PROFILE_PASSWORD[0].id && o.id != PROFILE_HAS_NOT_ACCEPTED_INVITATION[0].id), PROFILE_HAS_NOT_ACCEPTED_INVITATION[0]];
      dispatchAction(
          updateRouteOptions({
            routeOptions: updatedOptions
          })
        );
      return;
      
    }
    // Add reminder option to the list
  }, [user]);

  const handleChange = page => {
    setCurrent(page);
    setUser(portraits[page - 1]);
    setCurrentPortrait(portraits[page-1])
  };


  const updateAvatar = async fileInfo => {
    const isImage = /image\//.test(fileInfo.file.type);
    if (!fileInfo.file) {
      return;
    }

    if (!isImage) {
      message.error("You can only choose image files.");
      return;
    }

    const url = await getDataUrlFromBlob(fileInfo.file);
    setUser({
      ...user,
      previewProfileImage: {
        url: url,
        file: fileInfo.file,
        individualId: user.individualId
      }
    });
    handleFormChange({
      label: "previewProfileImage",
      value: { url, file: fileInfo.file, individualId: user.individualId }
    });
  };

  function renderCustomPagination(page = 1, type = '', originalElement = <></>) {
    if(type === 'page' && portraits.length > 1){
      return <span className="p-r-4 p-l-4" style={{color: 'white'}}>{`${page} of ${portraits.length}`}</span>;
    }
    return originalElement;
  }

  const metadata = useMemo(()=>{
    const title = `${user.firstname} ${user.lastname} - ${company.companyName}`;

    const otherNames = portraits.map(x => `${x.firstname} ${x.lastname}`).join(" - ");
    const description = `${user.firstname} ${user.lastname} - ${company.companyName} 
    ${otherNames} ${company.website ? ` - ${company.website}` : ""}`;
    return {
      title,
      description
    }
  }, [user, company]);

  return (
    user && (
      <div className="portrait">
        <SuperSEO
          title={metadata.title}
          description={metadata.description}
        >
          <link rel="canonical" href={window.location.origin + window.location.pathname} />
        </SuperSEO>
        <div style={{display: 'none'}}>
          <pre>
            {metadata.description}
          </pre>
        </div>
        {editMode ? (
          <Upload.Dragger
            listType="picture-card"
            showUploadList={false}
            className="basic-detail-upload"
            customRequest={e => updateAvatar(e)}
            style={{ height: "165px", flex: 1, width: "165px" }}
          >
            <Visible visible={!user?.previewProfileImage?.url}>
              Drag image here
            </Visible>
            <Visible visible={user?.previewProfileImage?.url}>
              <div
                style={{
                  backgroundImage: `url(${user?.previewProfileImage?.url}) `,
                  backgroundPosition: "center center ",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  objectFit: "contain",
                  width: "165px",
                  height: "165px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-18px"
                }}
              />
            </Visible>
          </Upload.Dragger>
        ) : (
          <UserProfileImage
            url={user?.imageFile?.fileUrl}
            height={165}
            width={165}
          />
        )}
        <div className="text-overflow" style={{ maxWidth: "280px" }}>
          {editMode ? (
            <PortraitForm
              form={form}
              user={user}
              handleFormChange={handleFormChange}
              isSystemUser={isSystemUser}
              company={company}
              portraits={portraits}
            />
          ) : (
            <Fragment>
              <div className="f-s-20 f-w-600">{`${user?.firstname} ${user?.lastname}`}</div>
              <div className="m-b-2 f-w-400 color-secondary">{`${user?.stateKey}, Australia`}</div>
              <a target="_top" href={`mailto:${user?.email}`}>
                {user?.email}
              </a>
              <div className="m-t-2 m-b-2">{user?.mobileNumber}</div>
              <div className="m-b-6">
                <a target="_blank" href={constructUrl(user?.linkedInProfile)}>
                  LinkedIn Profile
                </a>
              </div>
            </Fragment>
          )}
          <div className="flex align-center gap-24">
            {portraits?.length >= 1 && (
              <Pagination
                current={currentPage}
                pageSize={1}
                total={portraits.length}
                onChange={handleChange}
                className="portrait-pagination"
                itemRender={renderCustomPagination}
              />
            )}
            {editMode && isSystemUser &&  (
              <PortraitRemove id={user.individualId} name={`${user.firstname} ${user.lastname}`} companyName={company?.companyName} soleUser={portraits?.length === 1} handleRemove={handleRemove}/>
            )}
          </div>
        </div>
      </div>
    )
  );
};
export default PortraitView;

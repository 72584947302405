import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import APIHandler, { apiUrls } from "../constants/apiUrl";

export const sendEmailToTest = ({
  payload
}: {
  payload: FormData;
}) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls?.BULK_EMAIL_TEST_URL || ""
    });
    const response: any = await http.post(url, payload);

    if (ResponseHandler.isValidStatus(response)) {
      return ResponseHandler.validObject(response);
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

export const sendBulkEmail = ({
  payload
}: {
  payload: FormData;
}) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls?.BULK_EMAIL_SEND_URL || ""
    });
    const response: any = await http.postFormData(url, payload);

    if (ResponseHandler.isValidStatus(response)) {
      return ResponseHandler.validObject(response);
    } else {
      return ResponseHandler.inValidObject(response);
    }
  }
};

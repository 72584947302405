import { Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IBulkEmailRecipientLookupItem } from "../../libs/types";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { bulkEmailRecipientsLookup } from "../../actions/lookup";
import { mustBeArray } from "../../libs";
import Axios from "axios";

type RecipientSearchProps = {
  onSelect: (items: IBulkEmailRecipientLookupItem) => void;
  includeUnsubscribed: boolean;
  toSendList: IBulkEmailRecipientLookupItem[]
};

export default function RecipientSearch({ onSelect, includeUnsubscribed, toSendList }: RecipientSearchProps) {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();

  const [recipientList, setRecipientList] = useState<
    IBulkEmailRecipientLookupItem[]
  >([]);

  const getRecipients = _.debounce(async (value: string = "") => {
    const response = await dispatch(
      bulkEmailRecipientsLookup({
        search: value,
        includeUnsubscribed: includeUnsubscribed,
        cancelToken: signal.token
      })
    );
    setRecipientList(mustBeArray(response));
  }, 300);

  function handleSelect(value: string) {
    if (value !== "") {
      const parsed: IBulkEmailRecipientLookupItem = JSON.parse(value);
      onSelect(parsed);
    }
  }

  useEffect(() => {
    getRecipients();
  }, []);

  useEffect(() => {
    getRecipients();
  }, [toSendList])

  const filteredList = useMemo(()=>{
    return recipientList.filter(x => includeUnsubscribed ? x.isSubscribed || !x.isSubscribed : x.isSubscribed);
  }, [includeUnsubscribed, recipientList]);

  return (
    <Select
      placeholder="Search"
      onSearch={value => getRecipients(value || "")} // Default search is "A" to avoid blank option names on lookup
      filterOption={false}
      onSelect={(value: string) => {
        handleSelect(value);
      }}
      value={""}
      showSearch
    >
      {filteredList.map((v, i) => (
        <Select.Option key={`${v.key}`} value={JSON.stringify(v) || ""}>
          {v.name}
        </Select.Option>
      ))}
    </Select>
  );
}

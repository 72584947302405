import React from "react";
import FilesList from "../FilesList";
import { LAW_FIRM_USER } from "../../../constants/systemUsers";
import { Tabs } from "antd";
import Details from "../Details";
import LoansTable from "../../Loans/LoansTable";

const LawFirmProfileTabs = ({
  activeTab,
  onTabChange,
  match,
  userType,
  id,
  userHasPermissionToUpdate,
  dispatchAction,
  user,
  loanSearchQueryParams,
  handleSubTabChange,
  activeSubTab,
  isOwnProfile,
  isSytemUser
}) => {
  const updatedActiveSubKey =
    !isOwnProfile && !isSytemUser && activeSubTab === "preSettlementLoans"
      ? "currentLoans"
      : activeSubTab;
  return (
    <div className="profile-content">
      {!isOwnProfile && (
        <>
          <Tabs onChange={onTabChange} activeKey={activeTab} type="card">
            <Tabs.TabPane tab="Loans" key="loans">
              <Tabs
                onChange={handleSubTabChange}
                activeSubKey={updatedActiveSubKey ?? "preSettlementLoans"}
                type="card"
                className="has-sub-tabs"
              >
                {isSytemUser && (
                  <Tabs.TabPane tab="Pre-Settlement" key="preSettlementLoans">
                    <LoansTable
                      userId={id}
                      activeSubKey={updatedActiveSubKey}
                      loanSearchQueryParams={loanSearchQueryParams}
                      profileType={LAW_FIRM_USER}
                    />
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="Current" key="currentLoans">
                  <LoansTable
                    userId={id}
                    activeSubKey={updatedActiveSubKey}
                    loanSearchQueryParams={loanSearchQueryParams}
                    profileType={LAW_FIRM_USER}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                  <LoansTable
                    userId={id}
                    activeSubKey={updatedActiveSubKey}
                    loanSearchQueryParams={loanSearchQueryParams}
                    profileType={LAW_FIRM_USER}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Tabs.TabPane>
            <Tabs.TabPane tab="File Notes" key="fileNotes">
              <FilesList
                match={match}
                userType={userType}
                profileType={LAW_FIRM_USER}
                activeKey={activeTab}
                userId={id}
                userHasPermissionToUpdate={userHasPermissionToUpdate}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Details" key="details">
              <Details
                activeKey={activeTab}
                userId={id}
                dispatch={dispatchAction}
                user={user}
                profileType={LAW_FIRM_USER}
              />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
      {isOwnProfile && (
        <>
          <Tabs onChange={onTabChange} activeKey={activeTab} type="card">
            <Tabs.TabPane tab="Loans" key="loans">
              <Tabs
                onChange={handleSubTabChange}
                activeSubKey={activeSubTab ?? "preSettlementLoans"}
                type="card"
                className="has-sub-tabs"
              >
                <Tabs.TabPane tab="Pre-Settlement" key="preSettlementLoans">
                  <LoansTable
                    userId={id}
                    activeSubKey={activeSubTab}
                    loanSearchQueryParams={loanSearchQueryParams}
                    profileType={LAW_FIRM_USER}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Current" key="currentLoans">
                  <LoansTable
                    userId={id}
                    activeSubKey={activeSubTab}
                    loanSearchQueryParams={loanSearchQueryParams}
                    profileType={LAW_FIRM_USER}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                  <LoansTable
                    userId={id}
                    activeSubKey={activeSubTab}
                    loanSearchQueryParams={loanSearchQueryParams}
                    profileType={LAW_FIRM_USER}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </div>
  );
};
export default LawFirmProfileTabs;

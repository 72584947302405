import { Tabs } from "antd";
import React, { Fragment } from "react";
import { AuthorizedView } from "../../Common/Authorization/AuthorizedView";
import {
  isBrokerOwnProfilePolicy,
  isBrokerUserPolicy,
  isNotBrokerOwnProfilePolicy,
  isUserInvolvedPolicy,
  isUserUninvolvedPolicy
} from "../../Common/Authorization/policies/isBrokerUserPolicy";
import {
  isLenderUserPolicy,
  isOnlyLenderUserPolicy
} from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { BrokerDetailsTab } from "./BrokerDetailsTab";
import { BrokerMatchesList } from "./BrokerMatchesList";
import FilesList from "./FilesList";

import LoansTable from "../../components/Loans/LoansTable";
import { isLawFirmUserPolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";

const BrokerProfileTabsImpl = ({
  activeTab,
  activeSubTab,
  onTabChange,
  onSubTabChange,
  brokerId,
  profileType,
  match,
  isMatched,
  userType,
  userHasPermissionToUpdate,
  toggleSubscriptions,
  loanSearchQueryParams,
  generalView = false
}) => {
  return (
    <div className="profile-content">
      {!generalView ? (
        <>
          <AuthorizedView policy={isSystemUserPolicy}>
            <Fragment>
              <div className="card-container profile-content"></div>
              <Tabs
                onChange={e => onTabChange(e)}
                activeKey={activeTab}
                type="card"
              >
                <Tabs.TabPane tab="Loans" key="loanstab">
                  <Tabs
                    onChange={e => onSubTabChange(e)}
                    activeSubKey={
                      activeSubTab.length ? activeSubTab : "preSettlementLoans"
                    }
                    type="card"
                    className="has-sub-tabs"
                  >
                    {loanSearchQueryParams != null && (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable
                          userId={brokerId}
                          activeSubKey={activeSubTab}
                          profileType={"broker"}
                          loanSearchQueryParams={loanSearchQueryParams}
                        ></LoansTable>
                      </Tabs.TabPane>
                    )}
                    <Tabs.TabPane tab="Pre-Settlement" key="preSettlementLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={profileType}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Current" key="currentLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </Tabs.TabPane>
                <Tabs.TabPane tab="File Notes" key="fileNotes">
                  <FilesList
                    match={match}
                    userType={userType}
                    profileType={profileType}
                    activeKey={activeTab}
                    userId={brokerId}
                    userHasPermissionToUpdate={userHasPermissionToUpdate}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Details" key="details">
                  <BrokerDetailsTab
                    brokerId={brokerId}
                    activeKey={activeTab}
                    userHasPermissionToUpdate={userHasPermissionToUpdate}
                    toggleSubscriptions={toggleSubscriptions}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Matches" key="matches">
                  <BrokerMatchesList
                    profileType={profileType}
                    activeTab={activeTab}
                    brokerId={brokerId}
                    match={match}
                    userType={userType}
                    userHasPermissionToUpdate={userHasPermissionToUpdate}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Fragment>
          </AuthorizedView>
          {/* Dual Profile */}
          <AuthorizedView
            policy={[
              isBrokerUserPolicy,
              isLenderUserPolicy,
              isNotBrokerOwnProfilePolicy(brokerId),
              isUserInvolvedPolicy(isMatched)
            ]}
          >
            <Fragment>
              <div className="card-container profile-content"></div>
              <Tabs
                onChange={e => onTabChange(e)}
                activeKey={activeTab}
                type="card"
              >
                <Tabs.TabPane tab="Loans" key="loanstab">
                  <Tabs
                    onChange={e => onSubTabChange(e)}
                    activeSubKey={activeSubTab}
                    type="card"
                    className="has-sub-tabs"
                  >
                    {loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable
                          userId={brokerId}
                          activeSubKey={activeSubTab}
                          profileType={"broker"}
                          loanSearchQueryParams={loanSearchQueryParams}
                        ></LoansTable>
                      </Tabs.TabPane>
                    ) : (
                      ""
                    )}
                    <Tabs.TabPane tab="Current" key="currentLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      ></LoansTable>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      ></LoansTable>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Done with You" key="doneWithMeLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </Tabs.TabPane>
                <Tabs.TabPane tab="File Notes" key="fileNotes">
                  <FilesList
                    match={match}
                    userType={userType}
                    profileType={profileType}
                    activeKey={activeTab}
                    userId={brokerId}
                    userHasPermissionToUpdate={userHasPermissionToUpdate}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Details" key="details">
                  <BrokerDetailsTab
                    brokerId={brokerId}
                    activeKey={activeTab}
                    userHasPermissionToUpdate={userHasPermissionToUpdate}
                    toggleSubscriptions={toggleSubscriptions}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Fragment>
          </AuthorizedView>
          <AuthorizedView policy={isBrokerOwnProfilePolicy(brokerId)}>
            <Tabs
              onChange={e => onTabChange(e)}
              activeKey={activeTab}
              type="card"
            >
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={e => onSubTabChange(e)}
                  activeSubKey={
                    activeSubTab.length ? activeSubTab : "preSettlementLoans"
                  }
                  type="card"
                  className="has-sub-tabs"
                >
                  {loanSearchQueryParams != null ? (
                    <Tabs.TabPane
                      tab="Matching Your Search"
                      key="matchingYourSearchLoans"
                    >
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                        loanSearchQueryParams={loanSearchQueryParams}
                      ></LoansTable>
                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}
                  <Tabs.TabPane tab="Pre-Settlement" key="preSettlementLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"BROKER"}
                    ></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Current" key="currentLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                    ></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                    ></LoansTable>
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Matches" key="matches">
                <BrokerMatchesList
                  profileType={profileType}
                  userType={userType}
                  activeTab={activeTab}
                  brokerId={brokerId}
                  match={match}
                  userHasPermissionToUpdate={userHasPermissionToUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </AuthorizedView>
          <AuthorizedView policy={isOnlyLenderUserPolicy}>
            <Tabs
              onChange={e => onTabChange(e)}
              activeKey={activeTab}
              type="card"
            >
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={e => onSubTabChange(e)}
                  activeSubKey={
                    activeSubTab.length ? activeSubTab : "currentLoans"
                  }
                  type="card"
                  className="has-sub-tabs"
                >
                  {loanSearchQueryParams != null ? (
                    <Tabs.TabPane
                      tab="Matching Your Search"
                      key="matchingYourSearchLoans"
                    >
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                        loanSearchQueryParams={loanSearchQueryParams}
                      ></LoansTable>
                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}

                  <Tabs.TabPane tab="Current" key="currentLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                    ></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                    ></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Done with You" key="doneWithMeLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      />
                    </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="File Notes" key="fileNotes">
                <FilesList
                  match={match}
                  userType={userType}
                  profileType={profileType}
                  activeKey={activeTab}
                  userId={brokerId}
                  userHasPermissionToUpdate={userHasPermissionToUpdate}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="details">
                <BrokerDetailsTab
                  brokerId={brokerId}
                  activeKey={activeTab}
                  userHasPermissionToUpdate={userHasPermissionToUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </AuthorizedView>
          {/* Duplicate of above validation */}
          <AuthorizedView policy={isLawFirmUserPolicy}>
            <Tabs
              onChange={e => onTabChange(e)}
              activeKey={activeTab}
              type="card"
            >
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={e => onSubTabChange(e)}
                  activeSubKey={
                    activeSubTab.length ? activeSubTab : "currentLoans"
                  }
                  type="card"
                  className="has-sub-tabs"
                >
                  {loanSearchQueryParams != null ? (
                    <Tabs.TabPane
                      tab="Matching Your Search"
                      key="matchingYourSearchLoans"
                    >
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                        loanSearchQueryParams={loanSearchQueryParams}
                      ></LoansTable>
                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}

                  <Tabs.TabPane tab="Current" key="currentLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                    ></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                    ></LoansTable>
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="File Notes" key="fileNotes">
                <FilesList
                  match={match}
                  userType={userType}
                  profileType={profileType}
                  activeKey={activeTab}
                  userId={brokerId}
                  userHasPermissionToUpdate={userHasPermissionToUpdate}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="details">
                <BrokerDetailsTab
                  brokerId={brokerId}
                  activeKey={activeTab}
                  userHasPermissionToUpdate={userHasPermissionToUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </AuthorizedView>
          <AuthorizedView
            policy={[isUserUninvolvedPolicy(brokerId, isMatched)]}
          >
            <Fragment>
              <div className="card-container profile-content"></div>
              <Tabs
                onChange={e => onTabChange(e)}
                activeKey={activeTab}
                type="card"
              >
                <Tabs.TabPane tab="File Notes" key="fileNotes">
                  <FilesList
                    match={match}
                    userType={userType}
                    profileType={profileType}
                    activeKey={activeTab}
                    userId={brokerId}
                    userHasPermissionToUpdate={userHasPermissionToUpdate}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Details" key="details">
                <BrokerDetailsTab
                  brokerId={brokerId}
                  activeKey={activeTab}
                  userHasPermissionToUpdate={userHasPermissionToUpdate}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Loans" key="loanstab">
                  <Tabs
                    onChange={e => onSubTabChange(e)}
                    activeSubKey={activeSubTab}
                    type="card"
                    className="has-sub-tabs"
                  >
                    {loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable
                          userId={brokerId}
                          activeSubKey={activeSubTab}
                          profileType={"broker"}
                          loanSearchQueryParams={loanSearchQueryParams}
                        ></LoansTable>
                      </Tabs.TabPane>
                    ) : (
                      ""
                    )}
                    <Tabs.TabPane tab="Current" key="currentLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      ></LoansTable>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      ></LoansTable>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Done with You" key="doneWithMeLoans">
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </Tabs.TabPane>
                <Tabs.TabPane tab="File Notes" key="fileNotes">
                  <FilesList
                    match={match}
                    userType={userType}
                    profileType={profileType}
                    activeKey={activeTab}
                    userId={brokerId}
                    userHasPermissionToUpdate={userHasPermissionToUpdate}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Fragment>
          </AuthorizedView>
        </>
      ) : (
        <>
          <Fragment>
            <div className="card-container profile-content"></div>
            <Tabs
              onChange={e => onTabChange(e)}
              activeKey={activeTab}
              type="card"
            >
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={e => onSubTabChange(e)}
                  activeSubKey={
                    activeSubTab.length ? activeSubTab : "preSettlementLoans"
                  }
                  type="card"
                  className="has-sub-tabs"
                >
                  {loanSearchQueryParams != null && (
                    <Tabs.TabPane
                      tab="Matching Your Search"
                      key="matchingYourSearchLoans"
                    >
                      <LoansTable
                        userId={brokerId}
                        activeSubKey={activeSubTab}
                        profileType={"broker"}
                        loanSearchQueryParams={loanSearchQueryParams}
                        generalView={generalView}
                      ></LoansTable>
                    </Tabs.TabPane>
                  )}
                  <Tabs.TabPane tab="Current" key="currentLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                      generalView={generalView}
                    ></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    <LoansTable
                      userId={brokerId}
                      activeSubKey={activeSubTab}
                      profileType={"broker"}
                      generalView={generalView}
                    ></LoansTable>
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
            </Tabs>
          </Fragment>
        </>
      )}
    </div>
  );
};

export const BrokerProfileTabs = BrokerProfileTabsImpl;

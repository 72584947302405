import React from "react";
import { ColumnProps } from "antd/lib/table";
import { AU_DATE_FORMAT } from "../../../constants/moment";
import { BROKER_USER, LAW_FIRM_USER } from "../../../constants/systemUsers";

export interface UseLoansTableColumnsParams {
  profileType: string;
  generalView: boolean;
  sortInfo: {
    field: string;
    order: string;
  };
  handleLoanNumberClick: any;
  handleProfileClick: any;
  formatDecimalToCurrency: any;
  formatDecimal2: any;
  formatDecimalWithDefaultValue: any;
  asDefaultDisplayFormatDate: any;
  isUserLawfirm: boolean;
}

const useLoansTableColumns = ({
  profileType,
  generalView,
  sortInfo,
  handleLoanNumberClick,
  handleProfileClick,
  formatDecimal2,
  formatDecimalToCurrency,
  formatDecimalWithDefaultValue,
  asDefaultDisplayFormatDate,
  isUserLawfirm
}: UseLoansTableColumnsParams) => {
  const brokerColumns: ColumnProps<any>[] = !generalView
    ? [
        {
          title: (
            <span>
              Loan
              <br />
              No.
            </span>
          ),
          dataIndex: "loanNo",
          width: "8%",
          ellipsis: false,
          sorter: false,
          align: "left",
          sortOrder:
            sortInfo && sortInfo.field === "loanNo" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return (
              <span
                className={
                  record.loanLendersBrokers.length < 2 ? "hideExpandIcon" : ""
                }
                onClick={() => handleLoanNumberClick(record)}
                style={{ color: "blue" }}
              >
                {data}
              </span>
            );
          }
        },
        {
          title: "Lender",
          align: "left",
          dataIndex: "attributedLender",
          width: "15%",
          ellipsis: true,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "attributedLender" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return record.attributedLenderId ? (
              <span
                onClick={() => handleProfileClick(record, "lender")}
                style={{ color: "blue" }}
              >
                {data}
              </span>
            ) : (
              <span>{data}</span>
            );
          }
        },
        {
          title: (
            <span>
              Attributed
              <br />
              Broker
            </span>
          ),
          dataIndex: "attributedBroker",
          align: "left",
          width: "15%",
          sorter: false,
          ellipsis: true,
          sortOrder:
            sortInfo && sortInfo.field === "attributedBroker" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,

          render: (data, record) => {
            return (
              <span
                onClick={() => handleProfileClick(record, "broker")}
                style={{ color: "blue" }}
              >
                {data}
              </span>
            );
          }
        },
        {
          title: (
            <span>
              Suburb
              <br />& State
            </span>
          ),
          dataIndex: "suburbState",
          align: "left",
          width: "15%",
          ellipsis: true,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "suburbState" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: "Priority",
          align: "left",
          dataIndex: "priority",
          width: "8%",
          ellipsis: true,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "priority" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: "Principal",
          align: "left",
          dataIndex: "principal",
          width: "8%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "principal" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimalToCurrency(data)}</span>;
          }
        },
        {
          title: (
            <span>
              Lower
              <br />
              Rate
            </span>
          ),
          dataIndex: "lowerRate",
          align: "left",
          width: "5%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "lowerRate" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimal2(data)}</span>;
          }
        },
        {
          title: (
            <span>
              Estab
              <br />%
            </span>
          ),
          dataIndex: "estab",
          align: "left",
          width: "5%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "estab" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimalWithDefaultValue(data, 1)}</span>;
          }
        },
        {
          title: (
            <span>
              LVR
              <br />%
            </span>
          ),
          dataIndex: "lvr",
          align: "left",
          width: "5%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "lvr" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimal2(data)}</span>;
          }
        },
        {
          title: "Scenario",
          dataIndex: "scenario",
          align: "left",
          width: "8%",
          ellipsis: true,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "scenario" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: (
            <span>
              Security
              <br />
              Type
            </span>
          ),
          dataIndex: "securityType",
          align: "left",
          ellipsis: true,
          sorter: false,
          width: "8%",
          sortOrder:
            sortInfo && sortInfo.field === "scenario" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: "Settlement",
          dataIndex: "settlement",
          align: "left",
          width: "8%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "settlement" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          //   dataType: "date",
          //   dateFormat: AU_DATE_FORMAT,
          render: (data, record) => {
            return <span>{asDefaultDisplayFormatDate(data)}</span>;
          }
        }
      ]
    : [
        {
          title: (
            <span>
              Loan
              <br />
              No.
            </span>
          ),
          dataIndex: "loanNo",
          width: "10%",
          ellipsis: false,
          sorter: false,
          align: "left",
          sortOrder:
            sortInfo && sortInfo.field === "loanNo" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return (
              <span
                className={
                  record.loanLendersBrokers.length < 2 ? "hideExpandIcon" : ""
                }
                onClick={() => handleLoanNumberClick(record)}
                style={{ color: "blue" }}
              >
                {data}
              </span>
            );
          }
        },
        {
          title: (
            <span>
              Attributed
              <br />
              Broker
            </span>
          ),
          dataIndex: "attributedBroker",
          align: "left",
          width: "15%",
          sorter: false,
          ellipsis: true,
          sortOrder:
            sortInfo && sortInfo.field === "attributedBroker" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,

          render: (data, record) => {
            return (
              <span
                onClick={() => handleProfileClick(record, "broker")}
                style={{ color: "blue" }}
              >
                {data}
              </span>
            );
          }
        },
        {
          title: (
            <span>
              Suburb
              <br />& State
            </span>
          ),
          dataIndex: "suburbState",
          align: "left",
          width: "15%",
          ellipsis: true,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "suburbState" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: "Priority",
          align: "left",
          dataIndex: "priority",
          width: "10%",
          ellipsis: true,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "priority" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: "Principal",
          align: "left",
          dataIndex: "principal",
          width: "8%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "principal" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimalToCurrency(data)}</span>;
          }
        },
        {
          title: (
            <span>
              Lower
              <br />
              Rate
            </span>
          ),
          dataIndex: "lowerRate",
          align: "left",
          width: "5%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "lowerRate" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimal2(data)}</span>;
          }
        },
        {
          title: (
            <span>
              Estab
              <br />%
            </span>
          ),
          dataIndex: "estab",
          align: "left",
          width: "5%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "estab" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimalWithDefaultValue(data, 1)}</span>;
          }
        },
        {
          title: (
            <span>
              LVR
              <br />%
            </span>
          ),
          dataIndex: "lvr",
          align: "left",
          width: "5%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "lvr" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          render: (data, record) => {
            return <span>{formatDecimal2(data)}</span>;
          }
        },
        {
          title: "Scenario",
          dataIndex: "scenario",
          align: "left",
          width: "8%",
          ellipsis: true,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "scenario" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: (
            <span>
              Security
              <br />
              Type
            </span>
          ),
          dataIndex: "securityType",
          align: "left",
          ellipsis: true,
          sorter: false,
          width: "8%",
          sortOrder:
            sortInfo && sortInfo.field === "scenario" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false
        },
        {
          title: "Settlement",
          dataIndex: "settlement",
          align: "left",
          width: "10%",
          ellipsis: false,
          sorter: false,
          sortOrder:
            sortInfo && sortInfo.field === "settlement" && sortInfo.order
              ? sortInfo.order === "asc"
                ? "ascend"
                : "descend"
              : false,
          //   dataType: "date",
          //   dateFormat: AU_DATE_FORMAT,
          render: (data, record) => {
            return <span>{asDefaultDisplayFormatDate(data)}</span>;
          }
        }
      ];

  const lawfirmColumns: ColumnProps<any>[] = [
    {
      title: (
        <span>
          Loan
          <br />
          No.
        </span>
      ),
      dataIndex: "loanNo",
      width: "8%",
      ellipsis: false,
      sorter: false,
      align: "left",
      sortOrder:
        sortInfo && sortInfo.field === "loanNo" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return (
          <span
            className={
              record.loanLendersBrokers.length < 2 ? "hideExpandIcon" : ""
            }
            onClick={() => handleLoanNumberClick(record)}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        );
      }
    },
    {
      title: "Lender",
      align: "left",
      dataIndex: "attributedLender",
      width: "15%",
      ellipsis: true,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "attributedLender" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return record.attributedLenderId && !isUserLawfirm ? (
          <span
            onClick={() => handleProfileClick(record, "lender")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: (
        <span>
          Attributed
          <br />
          Broker
        </span>
      ),
      dataIndex: "attributedBroker",
      align: "left",
      width: "15%",
      sorter: false,
      ellipsis: true,
      sortOrder:
        sortInfo && sortInfo.field === "attributedBroker" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,

      render: (data, record) => {
        return !isUserLawfirm ? (
          <span
            onClick={() => handleProfileClick(record, "broker")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: (
        <span>
          Suburb
          <br />& State
        </span>
      ),
      dataIndex: "suburbState",
      align: "left",
      width: "15%",
      ellipsis: true,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "suburbState" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: "Priority",
      align: "left",
      dataIndex: "priority",
      width: "8%",
      ellipsis: true,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "priority" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: "Principal",
      align: "left",
      dataIndex: "principal",
      width: "8%",
      ellipsis: false,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "principal" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return <span>{formatDecimalToCurrency(data)}</span>;
      }
    },
    {
      title: (
        <span>
          Lower
          <br />
          Rate
        </span>
      ),
      dataIndex: "lowerRate",
      align: "left",
      width: "5%",
      ellipsis: false,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "lowerRate" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return <span>{formatDecimal2(data)}</span>;
      }
    },
    {
      title: (
        <span>
          Estab
          <br />%
        </span>
      ),
      dataIndex: "estab",
      align: "left",
      width: "5%",
      ellipsis: false,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "estab" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return <span>{formatDecimalWithDefaultValue(data, 1)}</span>;
      }
    },
    {
      title: (
        <span>
          LVR
          <br />%
        </span>
      ),
      dataIndex: "lvr",
      align: "left",
      width: "5%",
      ellipsis: false,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "lvr" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return <span>{formatDecimal2(data)}</span>;
      }
    },
    {
      title: "Scenario",
      dataIndex: "scenario",
      align: "left",
      width: "8%",
      ellipsis: true,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "scenario" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: (
        <span>
          Security
          <br />
          Type
        </span>
      ),
      dataIndex: "securityType",
      align: "left",
      ellipsis: true,
      sorter: false,
      width: "8%",
      sortOrder:
        sortInfo && sortInfo.field === "scenario" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: "Settlement",
      dataIndex: "settlement",
      align: "left",
      width: "8%",
      ellipsis: false,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "settlement" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      //   dataType: "date",
      //   dateFormat: AU_DATE_FORMAT,
      render: (data, record) => {
        return <span>{asDefaultDisplayFormatDate(data)}</span>;
      }
    }
  ];

  const lenderColumns: ColumnProps<any>[] = [
    {
      title: (
        <span>
          Loan
          <br />
          No.
        </span>
      ),
      dataIndex: "loanNo",
      width: "8%",
      ellipsis: false,
      sorter: false,
      align: "left",
      sortOrder:
        sortInfo && sortInfo.field === "loanNo" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return (
          <span
            className={
              record.loanLendersBrokers.length < 2 ? "hideExpandIcon" : ""
            }
            onClick={() => handleLoanNumberClick(record)}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        );
      }
    },
    {
      title: "Lender",
      align: "left",
      dataIndex: "attributedLender",
      width: "15%",
      ellipsis: true,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "attributedLender" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return record.attributedLenderId ? (
          <span
            onClick={() => handleProfileClick(record, "lender")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: (
        <span>
          Attributed
          <br />
          Broker
        </span>
      ),
      dataIndex: "attributedBroker",
      align: "left",
      width: "15%",
      sorter: false,
      ellipsis: true,
      sortOrder:
        sortInfo && sortInfo.field === "attributedBroker" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,

      render: (data, record) => {
        return (
          <span
            onClick={() => handleProfileClick(record, "broker")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        );
      }
    },
    {
      title: (
        <span>
          Suburb
          <br />& State
        </span>
      ),
      dataIndex: "suburbState",
      align: "left",
      width: "15%",
      sorter: false,
      ellipsis: true,
      sortOrder:
        sortInfo && sortInfo.field === "suburbState" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "left",
      width: "8%",
      sorter: false,
      ellipsis: true,
      sortOrder:
        sortInfo && sortInfo.field === "priority" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: "Principal",
      dataIndex: "principal",
      align: "left",
      width: "8%",
      sorter: false,
      ellipsis: true,
      sortOrder:
        sortInfo && sortInfo.field === "principal" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        // return <span>$ {data}</span>;
        return <span>{formatDecimalToCurrency(data)}</span>;
      }
    },
    {
      title: (
        <span>
          Lower <br /> Rate
        </span>
      ),
      dataIndex: "lowerRate",
      align: "left",
      width: "5%",
      sorter: false,
      ellipsis: true,
      sortOrder:
        sortInfo && sortInfo.field === "lowerRate" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return <span>{formatDecimal2(data)}</span>;
      }
    },
    {
      title: (
        <span>
          Estab
          <br />%
        </span>
      ),
      dataIndex: "estab",
      align: "left",
      width: "5%",
      sorter: false,
      ellipsis: false,
      sortOrder:
        sortInfo && sortInfo.field === "estab" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return <span>{formatDecimalWithDefaultValue(data, 1)}</span>;
      }
    },
    {
      title: (
        <span>
          LVR
          <br />%
        </span>
      ),
      dataIndex: "lvr",
      align: "left",
      width: "5%",
      sorter: false,
      ellipsis: false,
      sortOrder:
        sortInfo && sortInfo.field === "lvr" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return <span>{formatDecimal2(data)}</span>;
      }
    },
    {
      title: "Scenario",
      dataIndex: "scenario",
      align: "left",
      width: "8%",
      ellipsis: true,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "scenario" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: (
        <span>
          Security
          <br />
          Type
        </span>
      ),
      dataIndex: "securityType",
      align: "left",
      width: "8%",
      sorter: false,
      ellipsis: true,
      sortOrder:
        sortInfo && sortInfo.field === "scenario" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: "Settlement",
      dataIndex: "settlement",
      align: "left",
      width: "8%",
      sorter: false,
      ellipsis: false,
      sortOrder:
        sortInfo && sortInfo.field === "settlement" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      //   dataType: "date",
      //   dateFormat: AU_DATE_FORMAT,
      render: (data, record) => {
        return <span>{asDefaultDisplayFormatDate(data)}</span>;
      }
    }
  ];

  const colTemplate: ColumnProps<any>[] =
    profileType === "BROKER" || profileType === BROKER_USER
      ? brokerColumns
      : profileType === LAW_FIRM_USER
      ? lawfirmColumns
      : lenderColumns;

  const expandedRowLoanListColumns: ColumnProps<any>[] = [
    {
      dataIndex: "",
      key: "",
      width: "2%"
    },
    {
      title: "Lender List",
      dataIndex: "loanLenderName",
      key: "loanLenderName",
      align: "left",
      width: 200,
      render: (data, record) => {
        return record.loanLenderId && !isUserLawfirm ? (
          <span
            onClick={() => handleProfileClick(record, "lenderList")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      dataIndex: "loanLenderContribution",
      key: "loanLenderContribution",
      width: 200,
      align: "left",
      render: (data, record) => {
        return <span>{data ? formatDecimalToCurrency(data) : ""}</span>;
      }
    },
    {
      title: "Broker List",
      dataIndex: "loanBrokerName",
      key: "loanBrokerName",
      align: "left",
      width: 200,
      render: (data, record) => {
        return record.loanBrokerId && !isUserLawfirm ? (
          <span
            onClick={() => handleProfileClick(record, "brokerList")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      dataIndex: "",
      key: ""
    }
  ];

  return {
    colTemplate,
    expandedRowLoanListColumns
  };
};

export default useLoansTableColumns;

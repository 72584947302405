import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { LAW_FIRM_USER, LENDER_USER } from "../constants/systemUsers";

/**
 * 
 * @param {{
 *  cancelToken: any,
 *  id: string | number,
 *  userType: string
 * }} param0 
 * @returns 
 */
export const getEmailSubscriptions = ({ cancelToken, id, userType = LENDER_USER }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: userType === LAW_FIRM_USER ? apiUrls.GET_LAWFIRM_EMAIL_SUBSCRIPTIONS : apiUrls.GET_INDIVIDUAL_EMAIL_SUBSCRIPTIONS,
          options: {id}
        });
        const response =  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };

  export const updateEmailSubscriptions = ({ cancelToken, id, payload, userType = LENDER_USER }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: userType === LAW_FIRM_USER ? apiUrls.GET_LAWFIRM_EMAIL_SUBSCRIPTIONS : apiUrls.GET_INDIVIDUAL_EMAIL_SUBSCRIPTIONS,
          options: {id}
        });
        const response =  await http.put(url, payload, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };

/**
 * 
 * @param {{
*  cancelToken: any,
*  token: string,
* }} param0 
* @returns 
*/
export const getEmailPreferences = async ({ cancelToken, token}) => {
  const url = APIHandler.constructEndpoint({
    endpoint: apiUrls.EMAIL_PREFERENCES_URL,
    options: { token }
  });
  const response =  await http.get(url, cancelToken);
  if (ResponseHandler.isValidStatus(response)) {
    return  ( 
          response.data && response.data.result
            ? response.data.result
            : response.data
    );
  } else {
    return  ResponseHandler.inValidObject(response);
  } 
};

export const updateEmailPreferences = async ({ cancelToken, token, payload }) => {
  const url = APIHandler.constructEndpoint({
    endpoint: apiUrls.EMAIL_PREFERENCES_URL,
    options: {token}
  });
  const response =  await http.put(url, payload, cancelToken);
  if (ResponseHandler.isValidStatus(response)) {
    return  ( 
          response.data && response.data.result
            ? response.data.result
            : response.data
    );
  } else {
    return  ResponseHandler.inValidObject(response);
  } 
};
import React, { useEffect, useState } from "react";
import {
  ADMIN_USER,
  BROKER_USER,
  DUAL_USER,
  LAW_FIRM_USER,
  LENDER_USER,
  SYSTEM_ADMIN_USER
} from "../../constants/systemUsers";
import useCheckUserRoles from "./useCheckUserRoles";

type Role = "admin" | "lender" | "broker" | "dual" | "lawFirm" | "systemAdmin"; // update with constants/systemUsers

/**
 * Hook function to check user's roles if any listed is applicable
 *
 * Use constants from src/constants/systemUsers
 * @returns
 */
function useUserHasRoles() {
  const {
    isSystemAdmin,
    isBrokerUser,
    isDualUser,
    isLawFirmUser,
    isLenderUser
  } = useCheckUserRoles();

  function userHasRole(rolesToAllow: Role[]){
    let allowed = false;
    rolesToAllow.forEach(role => {
      if (role === LAW_FIRM_USER && isLawFirmUser) {
        allowed = true;
      }
      if (role === LENDER_USER && isLenderUser) {
        allowed = true;
      }
      if (role === BROKER_USER && isBrokerUser) {
        allowed = true;
      }
      if (role === DUAL_USER && isDualUser) {
        allowed = true;
      }
      if (
        (role === ADMIN_USER || role === SYSTEM_ADMIN_USER) &&
        isSystemAdmin
      ) {
        allowed = true;
      }
    });
    return allowed;
  }
  return userHasRole;
}

export default useUserHasRoles;

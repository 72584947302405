import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { lendersLookUpHandler, searchBoxHandler} from "../../actions/lookup";
import { errorDisplay, mustBeArray } from "../../libs/utilities";
import { Select, Spin } from "antd";
import { Visible } from "./visiblityWrapper";
import { updateHeader } from "../../actions/member";
import { ADMIN_USER, BROKER_USER, DUAL_USER, LAW_FIRM_USER, LENDER_USER } from "../../constants/systemUsers";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { clearProfileDetails } from "../../actions/profile";

const UniversalSearchBox = props => {
    const {
        searchBoxHandler,
        user,
        handleSearchBoxChange
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [searchResultList, setSearchResultList] = useState([]);
    const [userRole, setUserRole] = useState("");

    const getUsersProfile = async () => {
        var isSystemUser = await isSystemUserPolicy(
          user.roles,
          user.permissions,
          user
        );
        const roleType = isSystemUser ? ADMIN_USER : 
         user.role == "BROKER" ? BROKER_USER :  
         user.role == "LENDER" ? LENDER_USER : 
         user.roles.length == 2 ? DUAL_USER :
         user.role == "LAWFIRM" ? LAW_FIRM_USER : "";
        setUserRole(roleType);
      };

    useEffect(() => {
      getUsersProfile();
    }, []);

    useEffect(() => {
      if(user && user.roles && user.roles.length > 0){
        getUsersProfile();
      }
      
    }, [user]);

    useEffect(()=>{
      getSearchResults({ name: "", userType: userRole });
    },[userRole]);

    const getSearchResults = ({ name, userType }) => {
        var actionUrl = "";
        setIsLoading(true);
        if(userType){
          if(userType === ADMIN_USER){
            actionUrl = "SEARCH_BOX_FOR_SYSTEM_USER";
          }
          else {
              actionUrl = "SEARCH_BOX_URL";
          }
        }
        

        return searchBoxHandler({
          action: actionUrl,
          userType,
          queryParams: name ? { search: name } : {}
        }).then(resp => {
          if (resp.status) {
            setSearchResultList(mustBeArray(resp.data));
            setIsLoading(false);
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }
        });
      };
    const debouncedSearch = AwesomeDebouncePromise(async name => {
        setSearchResultList([]);
        setIsLoading(true);
        await getSearchResults({ name, userType: userRole });
      }, 500);

    const handleOnClick = (e) => {
      const selectedLender = searchResultList.filter(lender => 
        lender.key === e        
        )[0];
        handleSearchBoxChange(selectedLender, props);

    }


    return (
        <Fragment>
            <Select
                className="global-search"
                placeholder="Name"
                showSearch
                allowClear
                onChange={e => handleOnClick(e)}
                notFoundContent={
                  <Fragment>
                    <Visible visible={isLoading}>
                      <Spin size="small" />
                      ...
                    </Visible>
                    <Visible visible={!isLoading}>No data</Visible>
                  </Fragment>
                }
                onSearch={async e => await debouncedSearch(e)}
                filterOption={false}
              >
                {mustBeArray(searchResultList).map(item => {
                  return (
                    <Select.Option key={item.key} value={item.key} userprofiletype={item.userType}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
        </Fragment>
    );

};
const mapDispatchToProps = {
    updateHeader,
    lendersLookUpHandler,
    searchBoxHandler,
    clearProfileDetails
  };

const mapStateToProps = state => {
  return {
    member: state.member || {},
    user: state.authorization && state.authorization.user
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UniversalSearchBox);
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { updateLeadScore } from "../../../actions/profile";
import Axios from "axios";

interface LeadScoreProps {
  leadScoreValue: string | null;
  companyId: string;
  isSystemUser: boolean;
  onChange?:  (params: {label:string, value: string}) => void
}

const LeadScoreOptions = [
  {
    label: "A",
    value: "A"
  },
  {
    label: "B",
    value: "B"
  },
  {
    label: "C",
    value: "C"
  },
  {
    label: "D",
    value: "D"
  },
  {
    label: "E",
    value: "E"
  }
];

export default function LeadScore({
  leadScoreValue,
  isSystemUser,
  companyId,
  onChange = () => {}
}: LeadScoreProps) {
  const signal = Axios.CancelToken.source();

  const [leadScore, setLeadScore] = useState("");
  const [loading, setLoading] = useState(false);

  async function saveLeadScore(value: string) {
    setLoading(true);
    await updateLeadScore(signal.token, {
      id: companyId,
      leadScore: value
    }, isSystemUser);
    setLeadScore(value);
    onChange({
      label: "leadScore",
      value: value
    })
    setLoading(false);
  }

  useEffect(() => {
    setLeadScore(leadScoreValue || "");
  }, [leadScoreValue]);

  return (
    <div className={`flex flex-align-center m-l-20 gap-16`}>
      <b>Lead Score </b>
      <Select
        className="w-65"
        value={leadScore}
        onChange={(value: string) => saveLeadScore(value)}
        allowClear={false}
        disabled={loading}
        loading={loading}
      >
        {LeadScoreOptions.map(data => {
          return (
            <Select.Option key={data.value} value={data.value}>
              {data.label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

import { Tabs } from "antd";
import React, { Component, Fragment } from "react";
import { AuthorizedView } from "../../Common/Authorization/AuthorizedView";
import FirstRankingImproved from "../../components/Profile/FirstRankingImproved";
import SecondRankingImproved from "../../components/Profile/SecondRankingImproved";
import Construction from "../../components/Profile/Construction";
import Mezzanine from "../../components/Profile/Mezzanine";
import LandBank from "../../components/Profile/LandBank";
import Rural from "../../components/Profile/Rural";
import MatchesList from "../../components/Profile/MatchesList";
import Details from "../../components/Profile/Details";
import FilesList from "../../components/Profile/FilesList";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import {
  isLenderOwnProfilePolicy,
  isLenderUserPolicy,
  isNotLenderOwnProfilePolicy,
  isUserInvolvedPolicy,
  isUserUninvolvedPolicy
} from "../../Common/Authorization/policies/isLenderUserPolicy";
import {
  isBrokerUserPolicy,
  isOnlyBrokerUserPolicy
} from "../../Common/Authorization/policies/isBrokerUserPolicy";

import LoansTable from "../../components/Loans/LoansTable";
import Loading from "../../components/loading";
import { isLawFirmUserPolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";

class LenderProfile extends Component { 
  state={ 
    pageLoading: true
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({pageLoading: false});
    }, 500);
  }
  UNSAFE_componentWillReceiveProps(newProps){
    if(newProps.match.url !== this.props.match.url){
      this.setState({pageLoading: true});
      setTimeout(() => {
        this.setState({pageLoading: false})
      }, 500);
    } 
  }

 
  render() {

    const {
      handleTabChange,
      handleSubTabChange,
      activeKey,
      activeSubKey,
      lender,
      loanSearchQueryParams,
      match
    } = this.props;
    const firstLoanCriteria = lender.firstRankingLoanCriteriaStatus === 0 ? "loan-criteria-lapsed" : 
      (lender.firstRankingLoanCriteriaStatus === 1 ? "loan-criteria-verified" : "loan-criteria-cleared");
    const secondLoanCriteria = lender.secondRankingLoanCriteriaStatus === 0 ? "loan-criteria-lapsed" : 
      (lender.secondRankingLoanCriteriaStatus === 1 ? "loan-criteria-verified" : "loan-criteria-cleared");
    const constructionLoanCriteria = lender.constructionLoanCriteriaStatus === 0 ? "loan-criteria-lapsed" : 
      (lender.constructionLoanCriteriaStatus === 1 ? "loan-criteria-verified" : "loan-criteria-cleared");
    const mezzanineLoanCriteria = lender.mezzanineLoanCriteriaStatus === 0 ? "loan-criteria-lapsed" : 
      (lender.mezzanineLoanCriteriaStatus === 1 ? "loan-criteria-verified" : "loan-criteria-cleared");
    const landBankLoanCriteria = lender.landBankLoanCriteriaStatus === 0 ? "loan-criteria-lapsed" : 
      (lender.landBankLoanCriteriaStatus === 1 ? "loan-criteria-verified" : "loan-criteria-cleared");
    const ruralLoanCriteria = lender.ruralLoanCriteriaStatus === 0 ? "loan-criteria-lapsed" : 
      (lender.ruralLoanCriteriaStatus === 1 ? "loan-criteria-verified" : "loan-criteria-cleared");
    return ( 
      this.state.pageLoading ? 
      <Loading />
      :
      <div className="card-container profile-content">
        <AuthorizedView policy={isSystemUserPolicy}>
          <Fragment>
            <Tabs onChange={handleTabChange} type="card" activeKey={activeKey}>
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey.length ? activeSubKey : "preSettlementLoans"}
                  type="card"
                  className="has-sub-tabs"
                >
                  {
                    loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable {...this.props}></LoansTable>
                      </Tabs.TabPane>
                    ) : ''
                  }
                  
                  <Tabs.TabPane tab="Pre-Settlement" key="preSettlementLoans">
                    <LoansTable {...this.props}/>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Current" key="currentLoans">
                    <LoansTable {...this.props}/>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    <LoansTable {...this.props}/>
                  </Tabs.TabPane>
                  {
                      lender && ((activeSubKey === 'currentLoans' && !lender.showCurrentLoanBook) || (activeSubKey === 'dischargedLoans' && !lender.showDischargedLoanBook)) && 
                      (<Tabs.TabPane tab={<span className="darkred f-w-600">Privacy Setting Engaged</span>} disabled={true} key="privacySetting" className="darkred" >
                      </Tabs.TabPane>)
                  }
                  
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Loan Criteria" key="loans">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey}
                  type="card"
                  className="has-sub-tabs whole-tab loan-criteria"
                  defaultActiveKey="4"
                >
                  <Tabs.TabPane key="4" tab={
                      <div className={`${firstLoanCriteria}`}> 1st Ranking </div>
                    }>
                    <FirstRankingImproved {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="5" tab={
                      <div className={`${secondLoanCriteria}`}> 2nd Ranking </div>
                    }>
                    <SecondRankingImproved {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="6" tab={
                      <div className={`${constructionLoanCriteria}`}> Construction </div>
                    }>
                    <Construction {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="7" tab={
                      <div className={`${mezzanineLoanCriteria}`}> Mezzanine </div>
                    }>
                    <Mezzanine {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="8" tab={
                      <div className={`${landBankLoanCriteria}`}> Land Bank </div>
                    }>
                    <LandBank {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="9" tab={
                      <div className={`${ruralLoanCriteria}`}> Rural </div>
                    }>
                    <Rural {...this.props} />
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="File Notes" key="fileNotes">
                <FilesList {...this.props} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="3">
                <Details {...this.props} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Matches" key="matches">
                <MatchesList {...this.props} />
              </Tabs.TabPane>
            </Tabs>
          </Fragment>
        </AuthorizedView>
        {/* Dual Profile user */}
        <AuthorizedView policy={[isOnlyBrokerUserPolicy, isUserInvolvedPolicy(lender.isMatched)]}>
          <Fragment>
            <Tabs onChange={handleTabChange} type="card" activeKey={activeKey}>
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey.length ? activeSubKey : "currentLoans"}
                  type="card"
                  className="has-sub-tabs"
                >
                  {
                    loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable {...this.props}></LoansTable>
                      </Tabs.TabPane>
                    ) : ''
                  }
                  
                  <Tabs.TabPane tab="Current" key="currentLoans">
                  {
                    lender && lender.showCurrentLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Current Loans</h1>
                        </div>
                      )
                  }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    {
                    lender && lender.showDischargedLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Discharged Loans</h1>
                        </div>
                      )
                    }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Done with You" key="doneWithMeLoans">
                    <LoansTable {...this.props}/>
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Loan Criteria" key="loans">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey}
                  type="card"
                  className="has-sub-tabs"
                  defaultActiveKey="4"
                >
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.firstRanking && (
                      <Tabs.TabPane tab="1st Ranking" key="4">
                        <FirstRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.secondRanking && (
                      <Tabs.TabPane tab="2nd Ranking" key="5">
                        <SecondRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.construction && (
                      <Tabs.TabPane tab="Construction" key="6">
                        <Construction {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.mezzanine && (
                      <Tabs.TabPane tab="Mezzanine" key="7">
                        <Mezzanine {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.landBank && (
                      <Tabs.TabPane tab="Land Bank" key="8">
                        <LandBank {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.rural && (
                      <Tabs.TabPane tab="Rural" key="9">
                        <Rural {...this.props} />
                      </Tabs.TabPane>
                    )}
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="File Notes" key="fileNotes">
                <FilesList {...this.props} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="3">
                <Details {...this.props} />
              </Tabs.TabPane>
            </Tabs>
          </Fragment>
        </AuthorizedView>
        {/* TODO:  */}
        {/* This bit matches with isOnlyBrokerUserPolicy - Refactor required */}
        
        <AuthorizedView policy={isLawFirmUserPolicy}>
          <Fragment>
            <Tabs onChange={handleTabChange} type="card" activeKey={activeKey}>
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey.length ? activeSubKey : "currentLoans"}
                  type="card"
                  className="has-sub-tabs"
                >
                  {
                    loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable {...this.props}></LoansTable>
                      </Tabs.TabPane>
                    ) : ''
                  }
                  
                  <Tabs.TabPane tab="Current" key="currentLoans">
                  {
                    lender && lender.showCurrentLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Current Loans</h1>
                        </div>
                      )
                  }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    {
                    lender && lender.showDischargedLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Discharged Loans</h1>
                        </div>
                      )
                    }
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Loan Criteria" key="loans">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey}
                  type="card"
                  className="has-sub-tabs"
                  defaultActiveKey="4"
                >
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.firstRanking && (
                      <Tabs.TabPane tab="1st Ranking" key="4">
                        <FirstRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.secondRanking && (
                      <Tabs.TabPane tab="2nd Ranking" key="5">
                        <SecondRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.construction && (
                      <Tabs.TabPane tab="Construction" key="6">
                        <Construction {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.mezzanine && (
                      <Tabs.TabPane tab="Mezzanine" key="7">
                        <Mezzanine {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.landBank && (
                      <Tabs.TabPane tab="Land Bank" key="8">
                        <LandBank {...this.props} />
                      </Tabs.TabPane>
                    )}

                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.rural && (
                      <Tabs.TabPane tab="Rural" key="9">
                        <Rural {...this.props} />
                      </Tabs.TabPane>
                    )}
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="File Notes" key="fileNotes">
                <FilesList {...this.props} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="3">
                <Details {...this.props} />
              </Tabs.TabPane>
            </Tabs>
          </Fragment>
        </AuthorizedView>
        <AuthorizedView policy={isLenderOwnProfilePolicy(match?.params?.id)}>
          <Fragment>
            <Tabs onChange={handleTabChange} type="card" activeKey={activeKey}>
              <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey.length ? activeSubKey : "preSettlementLoans"}
                  type="card"
                  className="has-sub-tabs"
                >
                  {
                    loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable {...this.props}></LoansTable>
                      </Tabs.TabPane>
                    ) : ''
                  }
                  <Tabs.TabPane tab="Pre-Settlement" key="preSettlementLoans">
                    <LoansTable {...this.props}></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Current" key="currentLoans">
                    <LoansTable {...this.props}></LoansTable>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    <LoansTable {...this.props}></LoansTable>
                  </Tabs.TabPane>
                  {
                      lender && ((activeSubKey === 'currentLoans' && !lender.showCurrentLoanBook) || (activeSubKey === 'dischargedLoans' && !lender.showDischargedLoanBook)) && 
                      (<Tabs.TabPane tab={<span className="darkred f-w-600">Privacy Setting Engaged</span>} disabled={true} key="privacySetting" className="darkred" >
                      </Tabs.TabPane>)
                  }
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Loan Criteria" key="loans">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey}
                  type="card"
                  className="has-sub-tabs loan-criteria"
                  defaultActiveKey="4"
                >
                  <Tabs.TabPane key="4" tab={
                      <div className={`${firstLoanCriteria}`}> 1st Ranking </div>
                    }>
                    <FirstRankingImproved {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="5" tab={
                      <div className={`${secondLoanCriteria}`}> 2nd Ranking </div>
                    }>
                    <SecondRankingImproved {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="6" tab={
                      <div className={`${constructionLoanCriteria}`}> Construction </div>
                    }>
                    <Construction {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="7" tab={
                      <div className={`${mezzanineLoanCriteria}`}> Mezzanine </div>
                    }>
                    <Mezzanine {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="8" tab={
                      <div className={`${landBankLoanCriteria}`}> Land Bank </div>
                    }>
                    <LandBank {...this.props} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="9" tab={
                      <div className={`${ruralLoanCriteria}`}> Rural </div>
                    }>
                    <Rural {...this.props} />
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Matches" key="matches">
                <MatchesList {...this.props} />
              </Tabs.TabPane>
            </Tabs>
          </Fragment>
        </AuthorizedView>
        <AuthorizedView
          policy={[
            isBrokerUserPolicy,
            isLenderUserPolicy,
            isNotLenderOwnProfilePolicy(this.props.lenderId),
            isUserInvolvedPolicy(lender.isMatched)
          ]}
        >
          <Fragment>
            <Tabs onChange={handleTabChange} type="card" activeKey={activeKey}>
            <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey.length ? activeSubKey : "currentLoans"}
                  type="card"
                  className="has-sub-tabs"
                >
                  {
                    loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable {...this.props}></LoansTable>
                      </Tabs.TabPane>
                    ) : ''
                  }
                  
                  <Tabs.TabPane tab="Current" key="currentLoans">
                  {
                    lender && lender.showCurrentLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Current Loans</h1>
                        </div>
                      )
                  }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    {
                    lender && lender.showDischargedLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Discharged Loans</h1>
                        </div>
                      )
                    }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Done with You" key="doneWithMeLoans">
                    <LoansTable {...this.props}/>
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
            <Tabs.TabPane tab="Loan Criteria" key="loans">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey}
                  type="card"
                  className="has-sub-tabs"
                  defaultActiveKey="4"
                >
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.firstRanking && (
                      <Tabs.TabPane tab="1st Ranking" key="4">
                        <FirstRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.secondRanking && (
                      <Tabs.TabPane tab="2nd Ranking" key="5">
                        <SecondRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.construction && (
                      <Tabs.TabPane tab="Construction" key="6">
                        <Construction {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.mezzanine && (
                      <Tabs.TabPane tab="Mezzanine" key="7">
                        <Mezzanine {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.landBank && (
                      <Tabs.TabPane tab="Land Bank" key="8">
                        <LandBank {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.rural && (
                      <Tabs.TabPane tab="Rural" key="9">
                        <Rural {...this.props} />
                      </Tabs.TabPane>
                    )}
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="File Notes" key="fileNotes">
                <FilesList {...this.props} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="3">
                <Details {...this.props} />
              </Tabs.TabPane>
              
            </Tabs>
          </Fragment>
        </AuthorizedView>
        <AuthorizedView
          policy={[
            isUserUninvolvedPolicy(this.props.lenderId, lender.isMatched)
          ]}
        >
          <Fragment>
            <Tabs onChange={handleTabChange} type="card" activeKey={activeKey}>
            <Tabs.TabPane tab="Loans" key="loanstab">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey.length ? activeSubKey : "currentLoans"}
                  type="card"
                  className="has-sub-tabs"
                >
                  {
                    loanSearchQueryParams != null ? (
                      <Tabs.TabPane
                        tab="Matching Your Search"
                        key="matchingYourSearchLoans"
                      >
                        <LoansTable {...this.props}></LoansTable>
                      </Tabs.TabPane>
                    ) : ''
                  }
                  
                  <Tabs.TabPane tab="Current" key="currentLoans">
                  {
                    lender && lender.showCurrentLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Current Loans</h1>
                        </div>
                      )
                  }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Discharged" key="dischargedLoans">
                    {
                    lender && lender.showDischargedLoanBook ? (
                      <LoansTable {...this.props}></LoansTable>
                    ) : (
                        <div class='m-t-20'>
                          <h1 class='text-center darkred'>This Lender has engaged Privacy Settings for their Discharged Loans</h1>
                        </div>
                      )
                    }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Done with You" key="doneWithMeLoans">
                    <LoansTable {...this.props}/>
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
            <Tabs.TabPane tab="Loan Criteria" key="loans">
                <Tabs
                  onChange={handleSubTabChange}
                  activeSubKey={activeSubKey}
                  type="card"
                  className="has-sub-tabs"
                  defaultActiveKey="4"
                >
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.firstRanking && (
                      <Tabs.TabPane tab="1st Ranking" key="4">
                        <FirstRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.secondRanking && (
                      <Tabs.TabPane tab="2nd Ranking" key="5">
                        <SecondRankingImproved {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.construction && (
                      <Tabs.TabPane tab="Construction" key="6">
                        <Construction {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.mezzanine && (
                      <Tabs.TabPane tab="Mezzanine" key="7">
                        <Mezzanine {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.landBank && (
                      <Tabs.TabPane tab="Land Bank" key="8">
                        <LandBank {...this.props} />
                      </Tabs.TabPane>
                    )}
                  {lender &&
                    lender.categoriesOffered &&
                    lender.categoriesOffered.rural && (
                      <Tabs.TabPane tab="Rural" key="9">
                        <Rural {...this.props} />
                      </Tabs.TabPane>
                    )}
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab="File Notes" key="fileNotes">
                <FilesList {...this.props} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="3">
                <Details {...this.props} />
              </Tabs.TabPane>
            </Tabs>
          </Fragment>
        </AuthorizedView>
      </div>
    );
  }
}

export default LenderProfile;

import { Col, Form, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { updateHeader } from "../../actions/member";
import { Row } from "antd/es/grid";
import Loading from "../../components/loading";
import history from "../../routes/history";
import MarketingEmails from "../../components/EmailSubscriptions/marketingEmails";
import { FormComponentProps } from "antd/lib/form";
import { getEmailPreferences, updateEmailPreferences } from "../../actions/emailSubscriptions";
import { errorDisplay } from "../../libs";

interface EmailSubscriptionsPublicProps extends FormComponentProps {
  match: any;
}

const EmailSubscriptionsPublic = ({
  form,
  match
}: EmailSubscriptionsPublicProps) => {
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState("/login");
  const [isMasterSwitchOn, setIsMasterSwitchOn] = useState(false);
  const signal = axios.CancelToken.source();

  const { getFieldDecorator } = form;

  useEffect(() => {
    dispatch(
      updateHeader({
        header: {
          title: "",
          action: "",
          page: "emailSubscriptionPublic",
          enableBack: false,
          showSettings: false,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: false,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false,
          showSearchBox: false,
          allowSwitchProfile: false
        }
      })
    );
  }, []);

  useEffect(() => {
    getUserEmailSubscriptions();
  }, []);

  function getUserEmailSubscriptions() {
    const token = match.params.token;
    if (token) {
      getEmailPreferences({ cancelToken: signal.token, token }).then(resp => {
        setPageLoading(false);
        if (resp) {
          setIsMasterSwitchOn(resp.isMasterSwitchOn);
          const { marketingEmails } = resp;
          form.setFieldsValue({
            marketingEmails
          });
        }
      });
    }
  }

  const handleSave = () => {
    setProcessing(true);
    const token = match.params.token;
    const payload = {
      marketingEmails: form.getFieldValue("marketingEmails")
    };
    updateEmailPreferences({cancelToken: signal.token, token, payload })
    .then(resp => {
      if(resp && resp.data && resp.data.errors){
        errorDisplay(resp.data.errors);
        setProcessing(false);
      }
      else{
        message.success("Email subcription updated");
        setProcessing(false);
      }
    });
  };

  const defaultLabelColSpan = { span: 7 };
  const defaultValueColSpan = { span: 17 };
  return (
    <Form
      labelCol={defaultLabelColSpan}
      wrapperCol={defaultValueColSpan}
      className="p-lr-140 shrinked-form"
      colon={false}
    >
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-wrap w-percent-100 f-s-24 f-w-500 m-b-50 flex-center">
            Update Your Email Subscriptions
          </div>
          <Row
            gutter={24}
            className="m-t-24 flex"
            style={{ justifyContent: "center" }}
          >
            <Col md={24} lg={5}>
              <MarketingEmails
                canEdit={isMasterSwitchOn}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={24} lg={8} offset={8}>
              <div className="flex flex-wrap flex-center">
                <Button
                  icon="file"
                  type="primary"
                  className="f-w-500"
                  loading={processing}
                  disabled={!isMasterSwitchOn}
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
                <Button
                  className="f-w-500 m-l-15"
                  disabled={processing}
                  onClick={() => history.push(redirectionUrl)}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default Form.create({ name: "Email Subscriptions" })(
  EmailSubscriptionsPublic
);

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
  Upload
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import history from "../../routes/history";
import { FormComponentProps } from "antd/es/form/Form";
import { updateHeader } from "../../actions/member";
import TemplateLoader from "../../components/Common/tempateLoader";
import Axios from "axios";
import { marketingSenderLookup } from "../../actions/lookup";
import { errorDisplay, mustBeArray } from "../../libs";
import _ from "lodash";
import {
  IBulkEmailRecipientLookupItem,
  IBulkEmailSendError,
  IBulkEmailSendPayload,
  IMarketingSenderLookupItem
} from "../../libs/types";
import { sendBulkEmail, sendEmailToTest } from "../../actions/bulkEmail";
import RecipientSearch from "../../components/BulkEmail/RecipientSearch";
import { UploadChangeParam } from "antd/lib/upload";
import RequiredSign from "../../Common/RequiredSign";
import ConfirmationModal from "../../Common/ConfirmationModal";

interface CreateEmailProps extends FormComponentProps {}

const SYSTEM_EMAIL = "system@omicron.net.au";

export const CreateEmail = React.forwardRef(
  (
    {
      form: { getFieldDecorator, getFieldValue, validateFields }
    }: CreateEmailProps,
    ref
  ) => {
    const dispatch = useDispatch();
    const signal = Axios.CancelToken.source();

    const [testEmail, setTestEmail] = useState("");
    const [testEmailHasError, setTestEmailHasError] = useState(false);
    const [senderList, setSenderList] = useState<IMarketingSenderLookupItem[]>(
      []
    );
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

    const [toSendList, setToSendList] = useState<
      IBulkEmailRecipientLookupItem[]
    >([]);
    const [includeUnsubscribed, setIncludeUnsubscribed] = useState(false);

    const numberOfUnsubscribedUsers = useMemo(() => {
      var unsubscribedUsersCount = toSendList.filter(x => !x.isSubscribed && x.individualId).length
      var unsubscribedCompanyUsersCount = toSendList.filter(x => x.companyId).map(x => x.numberOfUnsubscribedUsers || 0).filter(x => x > 0).reduce((x, y) => x + y, 0)
      unsubscribedUsersCount = unsubscribedUsersCount + unsubscribedCompanyUsersCount;

      return unsubscribedUsersCount;
    }, [toSendList]);

    // Lookups
    const getSenders = _.debounce(async (search: string = "") => {
      const s = await dispatch(
        marketingSenderLookup({
          search,
          cancelToken: signal.token
        })
      );
      setSenderList(mustBeArray(s));
    }, 500);

    function displayToast(response: any) {
      if (response.status) {
        message.success("Email sent successfully");
      } else {
        if (response.data.errors) {
          const errors: IBulkEmailSendError[] = response.data.errors;
          errors.forEach(error => {
            message.error(error.message);
          });
        } else {
          console.error(response.data);
        }
      }
    }

    function handleSelectRecipient(item: IBulkEmailRecipientLookupItem) {
      const existingItem = toSendList.findIndex(x => x.key === item.key);
      if (existingItem === -1) {
        setToSendList(s => [...s, item]);
      }
    }

    function handleDeselectRecipient(itemKey: string) {
      setToSendList(s => s.filter(x => x.key !== itemKey));
    }

    function handleIncludeUnsubscribedChange(value: boolean){
      setIncludeUnsubscribed(value);
      setToSendList([]);
    }

    async function submit(type: "user" | "test" = "user") {
      validateFields(async (errors, values) => {
        if (errors) {
          message.error("Please check for missing fields");
          return;
        } else {
          try {
            if (type === "test") {
              const emailBody = getFieldValue("emailBody") || "";
              const emailSubject = getFieldValue("emailSubject");
              const senderEmail = getFieldValue("senderEmail");
              const attachments: UploadChangeParam | undefined = getFieldValue(
                "attachments"
              );
              const attachmentList = attachments ? attachments.fileList : [];
              let totalAttachmentSize = 0;
              const formData = new FormData();

              if (testEmail === "") {
                message.error("No test email/s added");
                setTestEmailHasError(true);
                return;
              }
              setIsSubmitLoading(true);
              const testEmails = testEmail.split(";");
              formData.append(
                "emailBody",
                JSON.stringify({
                  emailBody,
                  emailSubject,
                  senderEmail,
                  testEmails
                })
              );
              attachmentList.forEach(x => {
                if (x.originFileObj) {
                  totalAttachmentSize += x.originFileObj.size;
                  formData.append("attachments", x.originFileObj);
                }
              });
              if (totalAttachmentSize / 1024 ** 2 > 25) {
                message.error(
                  "Total attachments size cannot exceed more than 25MB"
                );
                return;
              }
              const response: any = await dispatch(
                sendEmailToTest({
                  payload: formData
                })
              );
              displayToast(response);
            } else {
              setIsSubmitLoading(true);

              if (
                numberOfUnsubscribedUsers > 0 &&
                values.senderEmail === SYSTEM_EMAIL
              ) {
                message.error(
                  `You cannot send an email to unsubscribed users as ${SYSTEM_EMAIL}`
                );
                return;
              }

              let companyIds: number[] = [];
              let individualIds: number[] = [];

              toSendList.forEach(x => {
                if (x.companyId) {
                  companyIds.push(x.companyId);
                } else if (x.individualId) {
                  individualIds.push(x.individualId);
                }
              });

              const payload: IBulkEmailSendPayload = {
                emailBody: values.emailBody || "",
                emailSubject: values.emailSubject,
                senderEmail: values.senderEmail,
                individualIds,
                companyIds,
                includeUnsubscribed
              };

              const attachments: UploadChangeParam | undefined = getFieldValue(
                "attachments"
              );
              const attachmentList = attachments ? attachments.fileList : [];
              let totalAttachmentSize = 0;
              const formData = new FormData();

              attachmentList.forEach(x => {
                if (x.originFileObj) {
                  totalAttachmentSize += x.originFileObj.size;
                  formData.append("attachments", x.originFileObj);
                }
              });
              formData.append("emailBody", JSON.stringify(payload));
              if (totalAttachmentSize / 1024 ** 2 > 25) {
                message.error(
                  "Total attachments size cannot exceed more than 25MB"
                );
                return;
              }
              const response: any = await dispatch(
                sendBulkEmail({
                  payload: formData
                })
              );
              displayToast(response);
            }
          } catch (error) {
            message.error(
              "The process could not be completed. Please try again."
            );
          } finally {
            setIsSubmitLoading(false);
          }
        }
      });
    }

    useEffect(() => {
      dispatch(
        updateHeader({
          header: {
            title: "Bulk Email",
            action: "",
            page: `bulk-email`,
            enableBack: false,
            showSettings: false,
            showMasterSwitch: false,
            showIsProfileActive: false,
            hasSwitchProfile: false,
            editMode: false,
            showNotesButton: false,
            showOmicronBDM: false,
            omicronBdm: null,
            showPageTitle: false
          }
        })
      );
      getSenders();
    }, []);

    return (
      <Layout.Content className="m-t-42">
        <Form
          onSubmit={e => {
            e.preventDefault();
            if (includeUnsubscribed && numberOfUnsubscribedUsers > 0) {
              setIsAlertModalOpen(true);
            } else {
              submit();
            }
          }}
        >
          {/* Filters start */}
          <div className="m-n-t-80 w-percent-100">
            <Row
              gutter={[16, 16]}
              type="flex"
              style={{ justifyContent: "center" }}
            >
              <Col>
                <h1>Bulk Email</h1>
              </Col>
            </Row>
          </div>
          {/* Filters end */}
          <Row className="m-t-32" gutter={[8, 8]}>
            <Col>
              <Row gutter={[8, 8]} type="flex">
                <Col span={1} className="flex align-center">
                  <Typography>Add</Typography>
                </Col>
                <Col span={9}>
                  <RecipientSearch
                    onSelect={handleSelectRecipient}
                    includeUnsubscribed={includeUnsubscribed}
                    toSendList={toSendList}
                  />
                </Col>
                <Col className="flex align-center gap-5">
                  <Switch
                    checked={includeUnsubscribed}
                    onChange={checked => {
                      handleIncludeUnsubscribedChange(checked);
                    }}
                  />
                  <Typography>
                    Include unsubscribed users
                  </Typography>
                </Col>
              </Row>
              <Row gutter={[8, 8]} type="flex">
                <Col span={1} className="flex align-center">
                  <Typography>To:</Typography>
                </Col>
                <Col span={9}>
                  <Select
                    mode="multiple"
                    placeholder="Recipients"
                    showSearch={false}
                    filterOption={false}
                    value={toSendList.map(x => x.key)}
                    onDeselect={handleDeselectRecipient}
                  >
                    {toSendList.map(v => (
                      <Select.Option key={v.key} value={v.key}>
                        {v.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={10}>
                  <Form.Item>
                    {getFieldDecorator("emailSubject", {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter email subject"
                        }
                      ]
                    })(<Input placeholder="Subject" />)}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <RequiredSign />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Typography.Title level={4}>
                Type your email here
              </Typography.Title>
            </Col>
            <Col>
              {getFieldDecorator("emailBody", {
                initialValue: "",
                preserve: true,
                validateTrigger: "onChange"
              })(<TemplateLoader />)}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Typography.Title level={4}>Attachments</Typography.Title>
            </Col>
            <Col span={3}>
              {getFieldDecorator("attachments")(
                <Upload multiple showUploadList beforeUpload={() => false}>
                  <Button type="primary" style={{ border: 0 }}>
                    <Icon type="plus-circle" /> Add Attachments
                  </Button>
                </Upload>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Row gutter={8} className="flex align-center">
                <Col span={3}>
                  <Typography.Text>Sender</Typography.Text>
                </Col>
                <Col span={12}>
                  {getFieldDecorator("senderEmail", {
                    rules: [{ required: true, message: "Select a sender" }],
                    initialValue: SYSTEM_EMAIL
                  })(
                    <Select
                      showSearch
                      onSearch={getSenders}
                      filterOption={false}
                    >
                      {senderList.map((v, i) => (
                        <Select.Option key={i} value={v.email || ""}>
                          {v.senderName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                htmlType="submit"
                className="m-r-8"
                disabled={
                  isSubmitLoading ||
                  (numberOfUnsubscribedUsers > 0 &&
                    getFieldValue("senderEmail") === SYSTEM_EMAIL)
                }
                title={
                  numberOfUnsubscribedUsers > 0 &&
                  getFieldValue("senderEmail") === SYSTEM_EMAIL
                    ? `You cannot send an email to unsubscribed users as ${SYSTEM_EMAIL}`
                    : ""
                }
              >
                Send
              </Button>
              <Button onClick={() => history.goBack()}>Cancel</Button>
            </Col>
            <Col span={8}>
              <Row gutter={8}>
                <Col span={3}>
                  <Button
                    type="primary"
                    className="m-r-8"
                    onClick={() => submit("test")}
                  >
                    Test
                  </Button>
                </Col>
                <Col span={16}>
                  <Input
                    style={{
                      outline: testEmailHasError ? "solid 1px red" : ""
                    }}
                    value={testEmail}
                    placeholder="Test email - multiple emails can be added, separated by semicolon ;"
                    onChange={({ target: { value } }) => {
                      setTestEmail(value);
                      setTestEmailHasError(false);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <ConfirmationModal
            visible={isAlertModalOpen}
            handleConfirm={() => {
              setIsAlertModalOpen(false);
              submit();
            }}
            handleModalClose={() => {
              setIsAlertModalOpen(false);
            }}
            title="Alert: Unsubsribed Recipients"
            confirmationText={
              <Typography>
                {numberOfUnsubscribedUsers > 1
                  ? `${numberOfUnsubscribedUsers} have`
                  : `${numberOfUnsubscribedUsers} has`}{" "}
                unsubscribed from marketing emails. Do you want to send this
                email?
              </Typography>
            }
          />
        </Form>
        <Modal
          closable={false}
          maskClosable={false}
          footer={null}
          centered
          title="Sending e-mails"
          visible={isSubmitLoading}
        >
          <p>
            This window will automatically close when the process is complete,
            or runs into a problem.
          </p>
        </Modal>
      </Layout.Content>
    );
  }
);

export default Form.create({ name: "marketingEmail" })(CreateEmail);

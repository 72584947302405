import { Form, Spin, message } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { connect, useSelector } from "react-redux";
import history from "../../routes/history";
import { updateHeader, updateRouteOptions } from "../../actions/member";
import {
  toggleSubscriptions,
  getBrokerProfile,
  setActiveProfile,
  profileStatusTurnOff,
  profileStatusTurnOn,
  masterSwitchTurnOff,
  masterSwitchTurnOn,
  updateIndividualProfileV2,
  updateProfileLoading
} from "../../actions/profile";
import { isBrokerOwnProfilePolicy } from "../../Common/Authorization/policies/isBrokerUserPolicy";
import {
  isLenderOwnProfilePolicy,
  isLenderUserPolicy
} from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { Visible } from "../../components/Common/visiblityWrapper";
import { BrokerProfileTabs } from "../../components/Profile/BrokerProfileTabs";
import {
  errorDisplay,
  evaluatePolicy,
  getActiveKey,
  getUsersProfileType,
  mustBeArray,
  userHasActionRights
} from "../../libs/utilities";
import PortraitView from "../../components/Profile/protrait-view";
import ProfileUtility from "../../components/Profile/Utility/utility";
import {
  ADMIN_USER,
  BROKER_USER,
  DUAL_USER,
  LENDER_USER
} from "../../constants/systemUsers";
import authService from "../../actions/AuthorizeService";
import {
  BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER,
  BROKER_PROFILE_ROUTES_WITH_LENDER_PROFILE__SYSTEM_USER,
  CAN_RESET_PROFILE_PASSWORD,
  EMAIL_SUBSCRIPTIONS_OPTION_BROKER_USER,
  PROFILE_EDIT_OPTION,
  PROFILE_HAS_NOT_ACCEPTED_INVITATION
} from "../../constants/routeOptions";
import { BROKER_PROFILE_ACTIVE } from "../../constants/action-type/profile";
import _ from "lodash";
import React from "react";
import ConfirmationModal from "../../Common/ConfirmationModal";
import {
  BROKER_MASTER_SWITCH_OFF_CONFIRMATION_TEXT,
  BROKER_MASTER_SWITCH_ON_CONFIRMATION_TEXT
} from "../../constants/warnings";
import { getProfileStatusWarning } from "../../libs/user";
import { FormDecoratorProvider } from "../../components/Common/decoratedFormControl";

import { dummyBroker } from "../../constants/DummyData/brokerprofile";

const BrokerProfileImpl = ({
  match,
  header,
  member,
  user,
  dispatch,
  profileType,
  activeProfile,
  broker,
  individualUser,
  form,
  profileLoading,
  generalView = false
}) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [portraitsUpdated, setPortraitsUpdated] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [activeSubTab, setActiveSubTab] = useState("");
  const [userType, setUserType] = useState(null);
  const [userHasPermissionToUpdate, setUsersPermission] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const userId = match.params.id;
  const loansSearch = useLocation().search;
  const loanSearchQueryParams = loansSearch.length
    ? queryString.parse(loansSearch)
    : null;
  const [profileStatusLoading, setProfileStatusLoading] = useState(false);
  const [canToggleProfileSwitch, setCanToggleProfileSwitch] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );
  const [confirmationText, setConfirmationText] = useState("");
  const [activeField, setActiveField] = useState("");
  const [current, setCurrent] = useState(1);
  const [currentPortrait, setCurrentPortrait] = useState(null);
  const [isSystemUser, setIsSystemUser] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);

  useEffect(() => {
    const auth = async () => {
      const authenticated = await authService.isAuthenticated();
      if (authenticated) history.push(`/users/${userId}/broker${loansSearch}`);
    };
    auth();
  }, []);

  useEffect(() => {
    if (user.roles.length > 0 && !initialized) {
      onInitialize();
      setInitialized(true);
    }
  }, [user]);

  useEffect(() => {
    dispatch(
      updateHeader({
        header: {
          title: "",
          action: "edit",
          page: "profile",
          enableBack: false,
          showSettings: !generalView,
          showMasterSwitch: !generalView,
          showIsProfileActive: !generalView,
          hasSwitchProfile: true,
          editMode: false,
          showNotesButton: !generalView,
          showOmicronBDM: true,
          currentPortraitIndividualId: broker.currentPortraitIndividualId
            ? broker.currentPortraitIndividualId
            : header.currentPortraitIndividualId,

          omicronBdm: { id: broker.omicronBdmId, name: broker.omicronBdm },
          showPageTitle: false,
        }
      })
    );
    setInitialActiveTab();
  }, [broker, isSystemUser]);

  useEffect(()=>{
    if(!_.isEmpty(userProfile)){
      dispatch(updateHeader({
        header: {
          ...header,
          showDeactivatedMessage: !isSystemUser && (userProfile.isMasterSwitchOn === undefined ? false : !userProfile.isMasterSwitchOn),
        }
      }));
    }
  },[userProfile]);

  useEffect(() => {
    onInitialize();
  }, [activeProfile && activeProfile.active]);

  const loadUserInformation = async () => {
    setLoading(true);
    setCurrent(1);
    const id = match?.params?.id;
    const isLender = await isLenderUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsSystemUser(systemUser);
    let usersBrokerId;
    if (!generalView) {
      let userDetails = await authService.getUser();
      usersBrokerId = userDetails["app.user.brokerid"];
      const isOwnProfile =
        (await evaluatePolicy(user, isBrokerOwnProfilePolicy(id))) ||
        (await evaluatePolicy(user, isLenderOwnProfilePolicy(id)));

      setCanToggleProfileSwitch(systemUser || isOwnProfile);
    }
    const roleType = systemUser
      ? ADMIN_USER
      : user.roles.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : "";
    let resp;
      resp = await dispatch(
        getBrokerProfile({
          id: userId,
          userType: roleType,
          notClearProfileImage: false,
          isUserViewingSelfProfile: usersBrokerId === id,
          generalView
        })
      );

    if (resp.status && resp.data) {
      dispatch(
        updateHeader({
          header: {
            ...(member?.header ?? {}),
            title: `${resp.data.firstname} ${resp.data.lastname}`,
            hasSwitchProfile: true,
            showOmicronBDM: true,
            omicronBdm: {
              id: resp.data.omicronBdmId,
              name: resp.data.omicronBdm
            },
            allowSwitchProfile: !generalView,
            showDeactivatedMessage: !systemUser ? !resp.data.isMasterSwitchOn : false,
          }
        })
      );

      const sortedPortraits = _.sortBy(
        resp.data.portraits ? resp.data.portraits : [],
        "appearanceOrder"
      );
      setUserProfile({ ...resp.data, portraits: sortedPortraits });
      setCurrentPortrait(sortedPortraits[0]);

      const isMyProfile = parseInt(user["app.user.brokerid"]) === resp.data.id;
      const brokerLenderId = resp.data.lenderId;
      dispatch(
        setActiveProfile(resp.data.id, brokerLenderId, BROKER_PROFILE_ACTIVE)
      );

      let newRouteOptions = brokerLenderId
        ? BROKER_PROFILE_ROUTES_WITH_LENDER_PROFILE__SYSTEM_USER
        : BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER;

      newRouteOptions = [...newRouteOptions].map(o => {
        o.name = o.id == 9 ? individualUser.companyName : o.name;
        return o;
      });

      const menuOptions = systemUser
        ? [...PROFILE_EDIT_OPTION]
        : isMyProfile
        ? [...EMAIL_SUBSCRIPTIONS_OPTION_BROKER_USER, ...PROFILE_EDIT_OPTION]
        : [];

      setPortraitsUpdated(!portraitsUpdated);
      await dispatch(
        updateRouteOptions({
          routeOptions: _.concat(newRouteOptions, menuOptions)
        })
      );
    }
    setLoading(false);
    dispatch(updateProfileLoading(false));
  };

  useEffect(() => {
    loadUserInformation();
  }, [window.location.href]);

  async function setInitialActiveTab() {
    if (!broker.resetTab) {
      return;
    }
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isOwnBrokerProfile = await isBrokerOwnProfilePolicy(broker.id)(
      user.roles,
      user.permissions,
      user
    );
    setUsersPermission(
      userHasActionRights({
        isSystemUser: systemUser,
        profileType,
        isOwnProfile: isOwnBrokerProfile
      })
    );
    const searchField =
      window.location &&
      window.location.search &&
      mustBeArray(window.location.search.split("="))[1];
    const updatedActiveTab = getActiveKey({
      profileType: "broker",
      activeKey: searchField
    });

    if (updatedActiveTab) return setActiveTab(updatedActiveTab);
  }

  /**
   * Similar implementation to loadUserInformation
   * but doesn't set non-route variables to state
   */
  async function updateMenu() {
    const isMyProfile = parseInt(user["app.user.brokerid"]) === userProfile.id;
    const brokerLenderId = userProfile.lenderId;
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );

    let newRouteOptions = brokerLenderId
      ? BROKER_PROFILE_ROUTES_WITH_LENDER_PROFILE__SYSTEM_USER
      : BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER;

    newRouteOptions = [...newRouteOptions].map(o => {
      o.name = o.id == 9 ? individualUser.companyName : o.name;
      return o;
    });

    const menuOptions = systemUser
      ? [...PROFILE_EDIT_OPTION]
      : isMyProfile
      ? [...EMAIL_SUBSCRIPTIONS_OPTION_BROKER_USER, ...PROFILE_EDIT_OPTION]
      : [];

    // Add additional menu options via concat
    const resendOption = !currentPortrait.hasAcceptedInvitation
      ? PROFILE_HAS_NOT_ACCEPTED_INVITATION
      : CAN_RESET_PROFILE_PASSWORD;

    await dispatch(
      updateRouteOptions({
        routeOptions: _.concat(newRouteOptions, menuOptions, resendOption)
      })
    );
  }

  useEffect(() => {
    if (currentPortrait != null) {
      updateMenu();
      dispatch(
        updateHeader({
          header: {
            ...(member?.header ?? {}),

            currentPortraitIndividualId: broker.currentPortraitIndividualId
              ? broker.currentPortraitIndividualId
              : currentPortrait.individualId
              ? currentPortrait.individualId
              : member.header.currentPortraitIndividualId,
            emailSubcriptionProfileType: ""
          }
        })
      );
    }
  }, [currentPortrait]);

  async function onInitialize() {
    const isLender = await isLenderUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isSysUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isOwnProfile = await isBrokerOwnProfilePolicy(
      match && match.params && match.params.id
    )(user.roles, user.permissions, user);
    const updatedUserType = getUsersProfileType({
      isOwnProfile,
      isSystemUser: isSysUser,
      profileType
    });
    setUserType(updatedUserType);
    setIsOwnProfile(isOwnProfile);

    if (loanSearchQueryParams != null) {
      setActiveTab("loanstab");
      setActiveSubTab("matchingYourSearchLoans");
    } else {
      if (isSysUser || isOwnProfile) {
        setActiveTab("matches");
      } else if (isLender) {
        setActiveTab("fileNotes");
      } else {
        setActiveTab("loanstab");
        setActiveSubTab("currentLoans");
      }
    }
  }

  useEffect(() => {
    if (!profileLoading) {
      onInitialize();
    }
  }, [profileLoading]);

  async function handleTabChange(newKey) {
    const isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isOwnProfile = await isBrokerOwnProfilePolicy(
      match && match.params && match.params.id
    )(user.roles, user.permissions, user);
    if (newKey === "loanstab") {
      setActiveTab(newKey);
      if (loanSearchQueryParams != null) {
        setActiveSubTab("matchingYourSearchLoans");
      } else {
        if (isSystemUser || isOwnProfile) {
          setActiveSubTab("preSettlementLoans");
        } else {
          setActiveSubTab("currentLoans");
        }
      }
    } else {
      setActiveTab(newKey);
    }
  }

  const handleProfileActiveChange = () => {
    const warningMessage = getProfileStatusWarning({
      profileType,
      userType,
      isProfileActive: !!broker?.isProfileActive
    });
    setConfirmationText(warningMessage);
    setActiveField("isProfileActive");
    setConfirmationModalVisible(true);
  };

  const handleConfirmProfileActiveChange = () => {
    const actionName = !!broker?.isProfileActive
      ? profileStatusTurnOff
      : profileStatusTurnOn;
    const actionMessage = !!broker?.isProfileActive
      ? "Profile paused successfully."
      : "Profile set to active successfully.";

    dispatch(actionName({ id: broker?.id, profileType: profileType })).then(
      resp => {
        handleModalClose();
        if (resp.status) {
          message.success(actionMessage);
          loadUserInformation();
        } else {
          errorDisplay(resp?.data?.errors);
        }
      }
    );
  };

  const handleMasterSwitchChange = () => {
    const text = broker?.isMasterSwitchOn
      ? BROKER_MASTER_SWITCH_OFF_CONFIRMATION_TEXT
      : BROKER_MASTER_SWITCH_ON_CONFIRMATION_TEXT;
    setConfirmationText(text);
    setActiveField("isMasterSwitchActive");
    setConfirmationModalVisible(true);
  };

  const handleConfirmMasterSwitchChange = () => {
    const actionName = broker?.isMasterSwitchOn
      ? masterSwitchTurnOff
      : masterSwitchTurnOn;
    const actionMessage = broker?.isMasterSwitchOn ? "off" : "on";
    dispatch(
      actionName({ id: broker?.companyId, profileType: profileType })
    ).then(resp => {
      if (resp.status) {
        message.success(`Master switch turned ${actionMessage} successfully.`);
        loadUserInformation();
      }
    });
  };

  const handleModalClose = () => {
    setConfirmationModalVisible(false);
    setActiveField("");
    setConfirmationText("");
  };

  const handleConfirm = () => {
    activeField === "isProfileActive"
      ? handleConfirmProfileActiveChange()
      : handleConfirmMasterSwitchChange();
    handleModalClose();
  };

  const handleFormChange = ({ label, value }) => {
    if (
      label === "website" ||
      label === "companyName" ||
      label === "companyACN" ||
      label === "previewCompanyLogo" ||
      label === "portraits" ||
      label === "leadScore" ||
      label === "followupDate"
    ) {
      setUserProfile({ ...userProfile, [label]: value });
      setPortraitsUpdated(
        label === "portraits" ? !portraitsUpdated : portraitsUpdated
      );
      return;
    }
    const updatedPortraits = [...userProfile.portraits].map((portrait, i) => {
      if (i === current - 1) {
        portrait[label] = value;
      }
      return portrait;
    });
    setUserProfile({ ...userProfile, portraits: updatedPortraits });
  };

  const handleSave = () => {
    form.validateFields(err => {
      if (err) {
        console.log(err, "error");
        return;
      }
      setSaveLoading(true);
      const updatedProfile = {
        portraits: userProfile.portraits,
        website: userProfile.website,
        omicronBdmId: header?.omicronBdm?.id,
        logoFile: userProfile.logoFile,
        individualId: userProfile.individualId,
        companyACN: userProfile.companyACN,
        companyName: userProfile.companyName,
        previewCompanyLogo: userProfile.previewCompanyLogo,
        leadScore: userProfile.leadScore,
        followupDate: userProfile.followupDate
      };
      dispatch(
        updateIndividualProfileV2(
          updatedProfile,
          isSystemUser,
          "broker",
          broker.id
        )
      ).then(resp => {
        setCurrent(1);
        setCurrentPortrait(userProfile.portraits[0]);
        setSaveLoading(false);
        if (resp.status) {
          message.success("Profile updated successfully.");

          updateHeader({ header: { editMode: false } });

          loadUserInformation();
        }
      });
    });
  };

  const handleRemove = () => {
    const updatedPortraits = [...userProfile.portraits].filter(
      (_portrait, i) => i !== current - 1
    );
    setUserProfile({ ...userProfile, portraits: updatedPortraits });
    setCurrent(1);
    setPortraitsUpdated(!portraitsUpdated);
    message.success(
      "User successfully deleted from this Profile and the Platform."
    );
  };

  return (
    <div className="m-n-t-20">
      {loading || profileLoading || userType === null ? (
        <Spin />
      ) : (
        <Fragment>
          <Visible visible={!loading && userType !== null}>
            <Form hideRequiredMark colon={false}>
              <FormDecoratorProvider decorator={getFieldDecorator}>
                <div className="flex align-center gap-16 m-t-20">
                  <PortraitView
                    portraits={userProfile?.portraits}
                    form={form}
                    handleFormChange={handleFormChange}
                    currentPage={current}
                    setCurrent={setCurrent}
                    setCurrentPortrait={setCurrentPortrait}
                    isSystemUser={isSystemUser}
                    company={{
                      companyACN: userProfile?.companyACN,
                      companyName: userProfile?.companyName,
                      website: userProfile?.website
                    }}
                    portraitsUpdated={portraitsUpdated}
                    handleRemove={handleRemove}
                  />
                  <ProfileUtility
                    user={userProfile}
                    userType={BROKER_USER}
                    showIsProfileActive={header.showIsProfileActive}
                    profileStatusLoading={profileStatusLoading}
                    canToggleProfileSwitch={canToggleProfileSwitch}
                    handleProfileActiveChange={handleProfileActiveChange}
                    showMasterSwitch={header.showMasterSwitch}
                    handleMasterSwitchChange={handleMasterSwitchChange}
                    form={form}
                    handleFormChange={handleFormChange}
                    handleSave={handleSave}
                    saveLoading={saveLoading}
                    isSystemUser={isSystemUser}
                    isOwnProfile={isOwnProfile}
                  />
                </div>
              </FormDecoratorProvider>
            </Form>
            <br></br>
            <BrokerProfileTabs
              activeTab={activeTab}
              activeSubTab={activeSubTab}
              brokerId={userId}
              isMatched={broker.isMatched}
              userType={userType}
              profileType={profileType}
              onTabChange={tab => handleTabChange(tab)}
              onSubTabChange={tab => setActiveSubTab(tab)}
              match={match}
              userHasPermissionToUpdate={userHasPermissionToUpdate}
              getBrokerProfile={options => dispatch(getBrokerProfile(options))}
              loanSearchQueryParams={loanSearchQueryParams}
              toggleSubscriptions={options =>
                dispatch(toggleSubscriptions(options))
              }
              generalView={generalView}
            />
          </Visible>
          {!generalView && (
            <ConfirmationModal
              confirmationText={confirmationText}
              visible={confirmationModalVisible}
              handleModalClose={handleModalClose}
              handleConfirm={handleConfirm}
              handleProfileActiveChange={handleProfileActiveChange}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

const stateMap = state => {
  return {
    header: state.member.header,
    member: state.member,
    broker: state.profile.broker,
    user: state.authorization.user,
    profileLoading: state.profile.profileLoading,
    activeProfile: state.profile && state.profile.activeProfile,
    individualUser:
      (state.authorization && state.authorization.individualUser) || {}
  };
};

export const BrokerProfile = connect(stateMap)(
  Form.create({ name: "BorkerProfile" })(BrokerProfileImpl)
);

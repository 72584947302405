import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { processRequest } from "./asyncActionProcessor";
import { GET_LENDER_PROFILE } from "../constants/action-type/lenderProfile";
import { ADMIN_USER, LAW_FIRM_USER, LENDER_USER } from "../constants/systemUsers";
import {
  SAVE_INDIVIDUAL_PROFILE,
  CLEAR_CURRENT_PROFILE_PICTURE,
  CLEAR_PROFILE_DETAILS,
  GET_INDIVIDUAL_DETAILS,
  UPDATE_CURRENT_PROFILE_LOADING,
  UPDATE_ACTIVE_PORTRAIT,
  GET_LAW_FIRM_PROFILE
} from "../constants/action-type/individual";
import { getProductType, mustBeArray, requestBuilder } from "../libs/utilities";
import { GET_BROKER_PROFILE } from "../constants/action-type/brokers";
import {
  SET_ACTIVE_PROFILE,
  SELECT_ACTIVE_PROFILE,
  LENDER_PROFILE_ACTIVE,
  BROKER_PROFILE_ACTIVE,
  SET_TAB_PROFILE_SETTINGS
} from "../constants/action-type/profile";
import history from "../routes/history";
import { updateActiveProfile } from "./authorization";
import { isSystemUserPolicy } from "../Common/Authorization/policies/isSystemUserPolicy";

export function updateIndividualProfile(
  id,
  details,
  imageBlob,
  logoBlob,
  isSystemUser
) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: isSystemUser
          ? apiUrls.INDIVIDUAL_DETAIL_SYSTEM_USER_URL
          : apiUrls.INDIVIDUAL_DETAIL_URL,
        options: { id }
      });

      try {
        const data = new FormData();
        if (imageBlob) {
          data.append("0", imageBlob);
        }

        if (logoBlob) {
          data.append("logo", logoBlob);
        }
        data.append("individual", JSON.stringify(details));
        const response = await http.putFormData(url, data);
        resolve(processRequest(dispatch, response, SAVE_INDIVIDUAL_PROFILE));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function updateIndividualProfileV2(
  details,
  isSystemUser,
  type,
  id,
  generalView = false
) {
  return dispatch =>
    new Promise(async resolve => {
      let apiUrl = "";
      switch (type) {
        case "lender":
          apiUrl = isSystemUser
            ? apiUrls.LENDER_UPDATE_SYSTEM_USER_URL
            : apiUrls.LENDER_UPDATE_INDIVIDUAL_URL;
          break;
        case LAW_FIRM_USER:
          apiUrl = isSystemUser
            ? apiUrls.LAW_FIRM_DETAIL_SYSTEM_USER_URL
            : apiUrls.LAW_FIRM_DETAIL_URL;
          break;
        default:
          apiUrl = isSystemUser
            ? apiUrls.BROKER_UPDATE_SYSTEM_USER_URL
            : apiUrls.BROKER_UPDATE_INDIVIDUAL_URL;
          break;
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrl,
        options: { id }
      });

      try {
        const data = new FormData();
        let tempPortraits = mustBeArray(details.portraits);
        tempPortraits = tempPortraits.map(portrait => {
          let tempPortrait = { ...portrait };
          if (tempPortrait.previewProfileImage) {
            data.append(
              `individual${tempPortrait.previewProfileImage.individualId}`,
              tempPortrait.previewProfileImage.file
            );
            tempPortrait.imageFileForDeletion = tempPortrait.imageFile
              ? { fileId: tempPortrait.imageFile.fileId }
              : null;
            tempPortrait.individualImageFileDto = tempPortrait.imageFile;
          }

          delete tempPortrait.state;
          delete tempPortrait.imageFile;
          delete tempPortrait.previewProfileImage;
          delete tempPortrait.hasAcceptedInvitation;

          if (!isSystemUser) {
            delete tempPortrait.firstname;
            delete tempPortrait.lastname;
            delete tempPortrait.mobileNumber;
            delete tempPortrait.email;
          }

          return tempPortrait;
        });

        let payload = { ...details, portraits: tempPortraits };
        if (payload.previewCompanyLogo) {
          data.append("logo", payload.previewCompanyLogo.file);
          delete payload.previewCompanyLogo;
          payload.logoFileForDeletion = payload.logoFile
            ? { fileId: payload.logoFile.fileId }
            : null;
        }
        delete payload.individualId;
        data.append("company", JSON.stringify(payload));
        const response = await http.putFormData(url, data);
        resolve(processRequest(dispatch, response, SAVE_INDIVIDUAL_PROFILE));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export const deleteIndividualPortrait = ({ id, cancelToken }) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.INDIVIDUALS_DELETE_URL,
        options: { id }
      });
      const response = await http.post(url, {}, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "DELETE_INDIVIDUAL_PORTRAIT"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export function clearCurrentProfilePicture() {
  return async dispatch => {
    return dispatch({
      type: CLEAR_CURRENT_PROFILE_PICTURE
    });
  };
}

export function setProfileTabSettings(resetActiveTab) {
  return dispatch => {
    dispatch({
      type: SET_TAB_PROFILE_SETTINGS,
      resetActiveTab
    });
  };
}

export function getIndividualDetails({ id, userType = LENDER_USER, notClearProfileImage }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      if (!notClearProfileImage) {
        dispatch(clearCurrentProfilePicture());
      }
      const url = APIHandler.constructEndpoint({
        endpoint: userType === LAW_FIRM_USER ? apiUrls.LAW_FIRM_DETAIL_URL : apiUrls.INDIVIDUALS_DETAIL_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(processRequest(dispatch, response, GET_INDIVIDUAL_DETAILS));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getBrokerProfile({
  id,
  userType,
  notClearProfileImage,
  doNotResetCurrentTab,
  isUserViewingSelfProfile,
  generalView = false
}) {
  return dispatch =>
    new Promise(async resolve => {

      if (!id) {
        throw new Error("Id is required");
      }
      if (!notClearProfileImage) {
        dispatch(clearCurrentProfilePicture());
      }
      dispatch(setProfileTabSettings(!doNotResetCurrentTab));
      const url = generalView ? APIHandler.constructEndpoint({
        endpoint: apiUrls.PUBLIC_BROKER_DETAILS_URL,
        options: { id }
      }) :  APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_DETAIL_SYSTEM_USER_URL
            : isUserViewingSelfProfile
            ? apiUrls.BROKER_SELF_DETAIL_URL
            : apiUrls.BROKER_DETAIL_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(processRequest(dispatch, response, GET_BROKER_PROFILE));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getLenderProfile({
  id,
  userType,
  notClearProfileImage,
  doNotResetCurrentTab,
  isUserViewingSelfProfile
}) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      if (!notClearProfileImage) {
        dispatch(clearCurrentProfilePicture());
      }
      dispatch(setProfileTabSettings(!doNotResetCurrentTab));
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LENDERS_DETAIL_SYSTEM_USER_URL
            : isUserViewingSelfProfile
            ? apiUrls.LENDERS_SELF_DETAIL_URL
            : apiUrls.LENDERS_DETAIL_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(processRequest(dispatch, response, GET_LENDER_PROFILE));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getLendersFirstRankingProfile({ id }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_FIRST_RANKING_DETAIL_PROFILE_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_FIRST_RANKING_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getLendersSecondRankingProfile({ id }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_SECOND_RANKING_DETAIL_PROFILE_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_SECOND_RANKING_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getLendersConstructionProfile({ id }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_CONSTRUCTION_DETAIL_PROFILE_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_CONSTRUCTION_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getLendersMezzanineProfile({ id }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_MEZZANINE_DETAIL_PROFILE_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_MEZZANINE_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getLendersLandBankProfile({ id }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_LANDBANK_DETAIL_PROFILE_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_LANDBANK_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function getLendersRuralProfile({ id }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_RURAL_DETAIL_PROFILE_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_RURAL_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export const saveProfile = ({ cancelToken, payLoad }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "OPEN_CASES_URL" });
      const response = await http.post(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveFirstRankingField = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_FIRST_RANKING_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_FIRST_RANKING_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveFirstRankingFieldAsAdmin = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_ADMIN_FIRST_RANKING_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_ADMIN_FIRST_RANKING_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveSecondRankingField = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_SECOND_RANKING_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_SECOND_RANKING_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveSecondRankingFieldAsAdmin = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_ADMIN_SECOND_RANKING_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_ADMIN_SECOND_RANKING_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveConstructionField = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_CONSTRUCTION_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_CONSTRUCTION_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveConstructionFieldAsAdmin = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_ADMIN_CONSTRUCTION_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_ADMIN_CONSTRUCTION_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveMezzanineField = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_MEZZANINE_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_MEZZANINE_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveMezzanineFieldAsAdmin = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_ADMIN_MEZZANINE_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_ADMIN_MEZZANINE_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveLandBankField = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_LANDBANK_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_LANDBANK_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveLandBankFieldAsAdmin = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_ADMIN_LANDBANK_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_ADMIN_LANDBANK_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveRuralField = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_RURAL_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_RURAL_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveRuralFieldAsAdmin = ({ cancelToken, payLoad, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          payLoad && payLoad.lastVerifiedDate
            ? apiUrls.LENDERS_ADMIN_RURAL_DETAIL_PROFILE_URL
            : apiUrls.LENDERS_ADMIN_RURAL_VERIFY_DRAFT_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

// Matches
export const listLendersMatches = ({
  cancelToken,
  queryParams = {},
  id,
  userType
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LENDERS_ADMIN_MATCHES_URL
            : apiUrls.LENDERS_MATCHES_URL,
        options: { id }
      });
      const response = await requestBuilder(
        null,
        url,
        queryParams,
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "LIST_LENDERS_MATCHES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const updateMatches = ({ cancelToken, payLoad = {} }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.UPDATE_MATCHES_STATUS_URL,
        options: {}
      });
      const response = await http.put(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "UPDATE_LENDERS_MATCHES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const updateMatchesLender = ({ cancelToken, payLoad = {} }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.UPDATE_MATCHES_STATUS_LENDER_URL,
        options: {}
      });
      const response = await http.put(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              // TODO: Assign correct reducer
              "UPDATE_LENDERS_MATCHES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const updateMatchesBroker = ({ cancelToken, payLoad = {} }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.UPDATE_MATCHES_STATUS_BROKER_URL,
        options: {}
      });
      const response = await http.put(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              // TODO: Assign correct reducer
              "UPDATE_LENDERS_MATCHES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

// Add Profiles
export const addBrokerProfile = ({ cancelToken, payLoad = {}, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANIES_ADD_BROKER_PROFILE_URL,
        options: { id }
      });
      const response = await http.post(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "ADD_BROKER_PROFILE_URL"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};
export const deleteBrokerProfile = ({ cancelToken, payLoad = {}, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANIES_BROKER_PROFILE_REMOVE_URL,
        options: { id }
      });
      const response = await http.post(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "UPDATE_LENDERS_MATCHES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const addLenderProfile = ({ cancelToken, payLoad = {}, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANIES_ADD_LENDER_PROFILE_URL,
        options: { id }
      });
      const response = await http.post(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "ADD_LENDER_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};
export const deleteLenderProfile = ({ cancelToken, payLoad = {}, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANIES_LENDER_PROFILE_REMOVE_URL,
        options: { id }
      });
      const response = await http.post(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "LENDER_PROFILE_DELETE_URL"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export function setActiveProfile(brokerId, lenderId, active) {
  return (dispatch, store) =>
    new Promise(async resolve => {
      const response = {
        status: 200,
        data: {
          brokerId,
          lenderId,
          active
        }
      };
      const user = store().authorization.user;
      const isSystemUser = await isSystemUserPolicy(
        user.roles,
        user.permissions,
        user
      );
      if (active === BROKER_PROFILE_ACTIVE && isSystemUser) {
        await dispatch(updateActiveProfile(brokerId, "BROKER"));
      } else if (active === LENDER_PROFILE_ACTIVE && isSystemUser) {
        await dispatch(updateActiveProfile(lenderId, "LENDER"));
      }
      resolve(processRequest(dispatch, response, SET_ACTIVE_PROFILE));
    });
}

export function clearProfileDetails () {
  return dispatch => {
    new Promise(async (resolve) => {
      const response = {
        type: CLEAR_PROFILE_DETAILS,
        status: 200,
        data: {}
      }
    return resolve(dispatch(response));

  }).catch(async error => {throw error.message});
  };
}

export function updateProfileLoading (profileLoading) {
  return dispatch => {
    new Promise(async (resolve) => {
      const response = {
        type: UPDATE_CURRENT_PROFILE_LOADING,
        status: 200,
        data: profileLoading
      }
    return resolve(dispatch(response));

  }).catch(async error => {throw error.message});
  };
}

export function switchActiveProfile(active) {
  return (dispatch, store) =>
    new Promise(async resolve => {
      const activeProfile = store().profile.activeProfile;
      await dispatch(
        setActiveProfile(activeProfile.brokerId, activeProfile.lenderId, active)
      );

      if (active === BROKER_PROFILE_ACTIVE) {
        history.push(`/users/${activeProfile.brokerId}/broker`);
      } else if (active === LENDER_PROFILE_ACTIVE) {
        history.push(`/users/${activeProfile.lenderId}/lender`);
      }

      const response = {
        status: 200,
        data: {}
      };
      resolve(processRequest(dispatch, response, SELECT_ACTIVE_PROFILE));
    });
}

export function masterSwitchTurnOn({ id, profileType }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }

      try {
        const url = APIHandler.constructEndpoint({
          endpoint: apiUrls.MASTER_SWITCH_TURN_ON_URL,
          options: { id }
        });
        const response = await http.put(url, {});
        resolve(
          processRequest(
            dispatch,
            response,
            `${profileType.toUpperCase()}_MASTER_SWITCH_TURN_ON`
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}
export const getPersonalDetails = ({ id, userType, profileType=LENDER_USER }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      let endpoint = 
      userType === ADMIN_USER
        ? apiUrls.LENDERS_PERSONAL_DETAIL_SYSTEM_USER_URL
        : apiUrls.LENDERS_PERSONAL_DETAIL_URL;
      if(profileType === LAW_FIRM_USER){
        endpoint = userType === ADMIN_USER ? apiUrls.LAW_FIRM_PERSONAL_DETAIL_SYSTEM_USER_URL : apiUrls.LAW_FIRM_PERSONAL_DETAIL_URL
      }
      const url = APIHandler.constructEndpoint({
        endpoint,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_PERSONAL_DETAIL_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getProfessionalDetails = ({ id, userType, profileType=LENDER_USER }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      let endpoint = userType === ADMIN_USER
      ? apiUrls.LENDERS_PROFESSIONAL_DETAIL_SYSTEM_USER_URL
      : apiUrls.LENDERS_PROFESSIONAL_DETAIL_URL
      if(profileType === LAW_FIRM_USER){
        endpoint = userType === ADMIN_USER ? apiUrls.LAW_FIRM_PROFESSIONAL_DETAIL_SYSTEM_USER_URL : apiUrls.LAW_FIRM_PROFESSIONAL_DETAIL_URL
      }
      const url = APIHandler.constructEndpoint({
        endpoint,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_PROFESSIONAL_DETAIL_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export function masterSwitchTurnOff({ id, profileType }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.MASTER_SWITCH_TURN_OFF_URL,
        options: { id }
      });
      try {
        const response = await http.put(url, {});
        resolve(
          processRequest(
            dispatch,
            response,
            `${profileType.toUpperCase()}_MASTER_SWITCH_TURN_OFF`
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export const saveProfessionalDetailsField = ({
  payLoad,
  id,
  userType,
  profileType=LENDER_USER
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      let endpoint = userType === ADMIN_USER
      ? apiUrls.LENDERS_PROFESSIONAL_DETAIL_SYSTEM_USER_URL
      : apiUrls.LENDERS_PROFESSIONAL_DETAIL_URL
      if(profileType === LAW_FIRM_USER){
        endpoint = userType === ADMIN_USER ? apiUrls.LAW_FIRM_PROFESSIONAL_DETAIL_SYSTEM_USER_URL : apiUrls.LAW_FIRM_PROFESSIONAL_DETAIL_URL
      }
      const url = APIHandler.constructEndpoint({
        endpoint,
        options: { id }
      });
      const response = await http.put(url, payLoad);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const savePersonalDetailsField = ({
  cancelToken,
  payLoad,
  id,
  userType,
  profileType=LENDER_USER
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      let endpoint = 
      userType === ADMIN_USER
        ? apiUrls.LENDERS_PERSONAL_DETAIL_SYSTEM_USER_URL
        : apiUrls.LENDERS_PERSONAL_DETAIL_URL;
      if(profileType === LAW_FIRM_USER){
        endpoint = userType === ADMIN_USER ? apiUrls.LAW_FIRM_PERSONAL_DETAIL_SYSTEM_USER_URL : apiUrls.LAW_FIRM_PERSONAL_DETAIL_URL
      }
      const url = APIHandler.constructEndpoint({
        endpoint,
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

// Profile status

export function profileStatusTurnOff({ id, profileType }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      let apiUrl = "";
      switch(profileType) {
        case "lender":
          apiUrl = apiUrls.LENDER_PROFILE_STATUS_OFF_URL;
          break;
        case LAW_FIRM_USER:
          apiUrl = apiUrls.LAW_FIRM_PAUSE_PROFILE_URL;
          break;
        default:
          apiUrl = apiUrls.BROKER_PROFILE_STATUS_OFF_URL;
          break;
      }
      const url = APIHandler.constructEndpoint({
        endpoint:apiUrl,
        options: { id }
      });
      try {
        const response = await http.put(url, {});
        resolve(
          processRequest(
            dispatch,
            response,
            `${profileType.toUpperCase()}_PROFILE_STATUS_TURN_OFF`
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function profileStatusTurnOn({ id, profileType }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      let endpoint = "";
      switch(profileType) {
        case "lender":
          endpoint = apiUrls.LENDER_PROFILE_STATUS_ON_URL;
          break;
        case LAW_FIRM_USER:
          endpoint = apiUrls.LAW_FIRM_ACTIVATE_PROFILE_URL;
          break;
        default:
          endpoint = apiUrls.BROKER_PROFILE_STATUS_ON_URL;
          break;
      }
      try {
        const url = APIHandler.constructEndpoint({
          endpoint,
          options: { id }
        });
        const response = await http.put(url, {});
        resolve(
          processRequest(
            dispatch,
            response,
            `${profileType.toUpperCase()}_PROFILE_STATUS_TURN_ON`
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export function toggleSubscriptions({ id, profileType, productType }) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }

      try {
        const url = APIHandler.constructEndpoint({
          endpoint:
            profileType === "lender"
              ? apiUrls.LENDER_PRODUCT_STATUS_TOGGLE
              : apiUrls.BROKER_PRODUCT_STATUS_TOGGLE,
          options: { id, productType: getProductType({ field: productType }) }
        });
        const response = await http.put(url, {});
        resolve(
          processRequest(
            dispatch,
            response,
            `${profileType.toUpperCase()}_UPDATE_PRODUCT_STATUS`
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
}

export const verifyFirstRankingFields = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_FIRST_RANKING_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_FIRST_RANKING_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyFirstRankingFieldsAsAdmin = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_ADMIN_FIRST_RANKING_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_FIRST_RANKING_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const clearDetailsFieldsAsAdmin = ({ id, categoryType, cancelToken }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_ADMIN_CLEAR_DETAILS_URL,
        options: { id }
      });
      const response = await http.put(url, categoryType, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const clearDetailsFields = ({ id, categoryType, cancelToken }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_CLEAR_DETAILS_URL,
        options: { id }
      });
      const response = await http.put(url, categoryType, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const verifySecondRankingFields = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_SECOND_RANKING_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_SECOND_RANKING_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifySecondRankingFieldsAsAdmin = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_ADMIN_SECOND_RANKING_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_LENDERS_SECOND_RANKING_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyConstructionFields = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_CONSTRUCTION_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_CONSTRUCTION_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyConstructionFieldsAsAdmin = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_ADMIN_CONSTRUCTION_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_CONSTRUCTION_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyMezzanineFields = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_MEZZANINE_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_MEZZANINE_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyMezzanineFieldsAsAdmin = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_ADMIN_MEZZANINE_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_MEZZANINE_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyLandBankFields = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_LANDBANK_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_LANDBANK_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyLandBankFieldsAsAdmin = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_ADMIN_LANDBANK_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_LANDBANK_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyRuralFields = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_RURAL_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_RURAL_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const verifyRuralFieldsAsAdmin = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LENDERS_ADMIN_RURAL_VERIFY_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LENDERS_RURAL_PROFILE")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getBrokerPersonalDetails = ({ id, userType }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_PERSONAL_DETAIL_SYSTEM_USER_URL
            : apiUrls.BROKER_PERSONAL_DETAIL_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_BROKERS_PERSONAL_DETAIL_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

// File notes for Lender
export const updateLenderFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LENDER_FILE_NOTES_SYSTEM_USER_URL
            : apiUrls.LENDER_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await http.put(url, payLoad, cancelToken);
        resolve(
          processRequest(dispatch, response, "UPDATE_LENDERS_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const createLenderFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LENDER_FILE_NOTES_SYSTEM_USER_URL
            : apiUrls.LENDER_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await http.post(url, payLoad, cancelToken);
        resolve(
          processRequest(dispatch, response, "CREATE_LENDERS_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const deleteLenderFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LENDER_FILE_NOTES_DELETE_SYSTEM_USER_URL
            : apiUrls.LENDER_FILE_NOTES_DELETE_URL,
        options: { id }
      });
      try {
        const response = await http.put(
          `${url}?id=${payLoad && payLoad.id}`,
          payLoad,
          cancelToken
        );
        resolve(
          processRequest(dispatch, response, "DELETE_LENDERS_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

// File Notes for Broker

export const getBrokerFileNotes = ({
  id,
  userType,
  cancelToken,
  queryParams
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_FILE_NOTES_SYSTEM_USER_URL
            : apiUrls.BROKER_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "GET_BROKERS_FILE_NOTES"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const updateBrokerFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_FILE_NOTES_SYSTEM_USER_URL
            : apiUrls.BROKER_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await http.put(url, payLoad, cancelToken);
        resolve(
          processRequest(dispatch, response, "UPDATE_BROKERS_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const createBrokerFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_FILE_NOTES_SYSTEM_USER_URL
            : apiUrls.BROKER_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await http.post(url, payLoad, cancelToken);
        resolve(
          processRequest(dispatch, response, "UPDATE_BROKERS_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const deleteBrokerFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_FILE_NOTES_DELETE_SYSTEM_USER_URL
            : apiUrls.BROKER_FILE_NOTES_DELETE_URL,
        options: { id }
      });
      try {
        const response = await http.put(
          `${url}?id=${payLoad && payLoad.id}`,
          payLoad,
          cancelToken
        );
        resolve(
          processRequest(dispatch, response, "DELETE_BROKERS_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};
export const saveBrokerProfessionalDetailsField = ({
  payLoad,
  id,
  userType
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_PROFESSIONAL_DETAIL_SYSTEM_USER_URL
            : apiUrls.BROKER_PROFESSIONAL_DETAIL_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const saveBrokerPersonalDetailsField = ({
  payLoad,
  id,
  userType
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_PERSONAL_DETAIL_SYSTEM_USER_URL
            : apiUrls.BROKER_PERSONAL_DETAIL_URL,
        options: { id }
      });
      const response = await http.put(url, payLoad);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "SAVE_PROFILE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};
export const getLenderFileNotes = ({ id, userType }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LENDER_FILE_NOTES_SYSTEM_USER_URL
            : apiUrls.LENDER_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(processRequest(dispatch, response, "GET_LENDERS_FILE_NOTES"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getLawFirmFileNotes = ({ id, userType, queryParams, cancelToken }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const endpoint = userType === ADMIN_USER ? apiUrls.LAW_FIRM_FILE_NOTES_SYSTEM_USER_URL : apiUrls.LAW_FIRM_FILE_NOTES_URL;
      const actionType = "GET_LAW_FIRM_FILE_NOTES";
      const url = APIHandler.constructEndpoint({
        endpoint,
        options: { id }
      });
      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams ? queryParams : {},
          cancelToken
        );
        resolve(processRequest(dispatch, response, actionType));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const updateLawFirmFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LAW_FIRM_FILE_NOTES_SYSTEM_USER_URL
            : apiUrls.LAW_FIRM_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await http.put(url, payLoad, cancelToken);
        resolve(
          processRequest(dispatch, response, "UPDATE_LAW_FIRM_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const createLawFirmFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
        userType === ADMIN_USER
        ? apiUrls.LAW_FIRM_FILE_NOTES_SYSTEM_USER_URL
        : apiUrls.LAW_FIRM_FILE_NOTES_URL,
        options: { id }
      });
      try {
        const response = await http.post(url, payLoad, cancelToken);
        resolve(
          processRequest(dispatch, response, "CREATE_LAW_FIRM_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const deleteLawFirmFileNotes = ({
  id,
  userType,
  payLoad,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LAW_FIRM_FILE_NOTES_DELETE_SYSTEM_USER_URL
            : apiUrls.LAW_FIRM_FILE_NOTES_DELETE_URL,
        options: { id }
      });
      try {
        const response = await http.put(
          `${url}?id=${payLoad && payLoad.id}`,
          payLoad,
          cancelToken
        );
        resolve(
          processRequest(dispatch, response, "DELETE_LAW_FIRM_FILE_NOTES")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getBrokerProfessionalDetails = ({ id, userType }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_PROFESSIONAL_DETAIL_SYSTEM_USER_URL
            : apiUrls.BROKER_PROFESSIONAL_DETAIL_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(
            dispatch,
            response,
            "GET_BROKERS_PROFESSIONAL_DETAIL_PROFILE"
          )
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const resendInvitation = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }

      try {
        const url = APIHandler.constructEndpoint({
          endpoint: apiUrls.INDIVIDUALS_RESEND_INVITATION,
          options: { id }
        });
        const response = await http.put(url, {});
        resolve(processRequest(dispatch, response, "RESEND_INVITATION"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const resetPassword = ({ id }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }

      try {
        const url = APIHandler.constructEndpoint({
          endpoint: apiUrls.INDIVIDUALS_RESET_PASSWORD,
          options: { id }
        });
        const response = await http.post(url, {});
        resolve(processRequest(dispatch, response, "RESET_PASSWORD"));
        return response;
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const deleteFinancierAccount = ({ cancelToken, payLoad = {}, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANIES_DELETE_URL,
        options: { id }
      });
      const response = await http.post(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "COMPANIES_DELETE_URL"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export function updateActivePortrait (activePortrait) {
  return dispatch => {
    new Promise(async (resolve) => {
      const response = {
        type: UPDATE_ACTIVE_PORTRAIT,
        status: 200,
        data: activePortrait
      }
    return resolve(dispatch(response));
  }).catch(async error => {throw error.message});
  };
}

// Law Firm
export function getLawFirmProfile({
  id,
  isUserViewingSelfProfile,
  isAdmin
}) {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }

      const url = APIHandler.constructEndpoint({
        endpoint: isAdmin
            ? apiUrls.LAW_FIRM_DETAIL_SYSTEM_USER_URL
            : isUserViewingSelfProfile
            ? apiUrls.LAW_FIRM_SELF_DETAIL_URL
            : apiUrls.LAW_FIRM_DETAIL_URL,
        options: { id }
      });
      try {
        const response = await http.get(url);
        return resolve(processRequest(dispatch, response, GET_LAW_FIRM_PROFILE));
      } catch (err) {
        return resolve(processRequest(dispatch, err));
      }
    });
}

export const deleteLawFirmAccount = ({ cancelToken, payLoad = {}, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANIES_DELETE_LAWFIRM_URL,
        options: { id }
      });
      const response = await http.post(url, payLoad, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "COMPANIES_DELETE_LAWFIRM_URL"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

// Lead Score
/**
 * @typedef UpdateLeadScorePayload
 * @property {string} id
 * @property {string} leadScore
 * 
 * @param {any} cancelToken
 * @param {UpdateLeadScorePayload} [payload]
 * @param {boolean} [isSystemUser=false] 
 */
export async function updateLeadScore(
  cancelToken,
  payload,
  isSystemUser = false
) {
  const url = APIHandler.constructEndpoint({
    endpoint: isSystemUser
      ? apiUrls.COMPANIES_UPDATE_LEAD_SCORE_AS_SYSTEM_USER
      : apiUrls.COMPANIES_UPDATE_LEAD_SCORE,
    options: {
      id: payload.id
    }
  });
  const response = await http.put(
    url,
    {
      leadScore: payload.leadScore
    },
    cancelToken
  );
  return response;
}

// Follow Up
/**
 * @typedef UpdateFollowUpDatePayload
 * @property {string} id
 * @property {string} followupDate
 * 
 * @param {any} cancelToken
 * @param {UpdateFollowUpDatePayload} [payload]
 * @param {boolean} [isSystemUser=false] 
 */
export async function updateFollowUpDate(
  cancelToken,
  payload,
  isSystemUser = false
) {
  const url = APIHandler.constructEndpoint({
    endpoint: isSystemUser
      ? apiUrls.COMPANIES_UPDATE_FOLLOWUP_DATE_AS_SYSTEM_USER
      : apiUrls.COMPANIES_UPDATE_FOLLOWUP_DATE,
    options: {
      id: payload.id
    }
  });
  const response = await http.put(
    url,
    {
      followupDate: payload.followupDate
    },
    cancelToken
  );
  return response;
}
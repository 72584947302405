import { DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { updateFollowUpDate } from "../../../actions/profile";
import Axios from "axios";

interface FollowUpProps {
  followupDate: string | null;
  companyId: string;
  isSystemUser: boolean;
  onChange?: (params: { label: string; value: string }) => void;
}

// value uses formatting for moment.add()
const AddAmountOptions = [
  {
    label: "One Week",
    value: "1w"
  },
  {
    label: "Two Weeks",
    value: "2w"
  },
  {
    label: "Three Weeks",
    value: "3w"
  },
  {
    label: "One Month",
    value: "1M"
  },
  {
    label: "Two Months",
    value: "2M"
  },
  {
    label: "Three Months",
    value: "3M"
  },
  {
    label: "Six Months",
    value: "6M"
  },
  {
    label: "One Year",
    value: "1y"
  }
];

export default function FollowUp({
  followupDate,
  isSystemUser,
  companyId,
  onChange = () => {}
}: FollowUpProps) {
  const signal = Axios.CancelToken.source();

  const [dateValue, setDateValue] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState(false);

  async function saveFollowupDate(date?: moment.Moment | null) {
    setLoading(true);
    const followupDate = date
      ? date.toISOString()
      : dateValue
      ? dateValue.toISOString()
      : "";
    await updateFollowUpDate(
      signal.token,
      {
        id: companyId,
        followupDate
      },
      isSystemUser
    );
    onChange({
      label: "followupDate",
      value: followupDate
    });
    setLoading(false);
  }

  function handleChangeFollowUpDate(value: moment.Moment | null) {
    setDateValue(value);
    saveFollowupDate(value);
  }

  function handleAddDate(valueToAdd: string) {
    if (valueToAdd && typeof valueToAdd === "string") {
      const format = valueToAdd.split("");
      const amount = parseInt(format[0]) || 0;
      const unit: any = format[1] || "ms";
      handleChangeFollowUpDate(moment().add(amount, unit));
    }
  }

  useEffect(() => {
    setDateValue(
      moment(followupDate || "").isValid() ? moment(followupDate || "") : null
    );
  }, [followupDate]);

  return (
    <>
      <div className={`flex flex-align-center m-l-20 gap-16`}>
        <b>Follow up </b>
        <DatePicker
          className="m-l-6 w-150"
          onChange={handleChangeFollowUpDate}
          value={dateValue}
          disabled={loading}
        />
      </div>
      <div className={`flex flex-align-center m-l-20 gap-16`}>
        <b>Add</b>
        <Select
          className="w-120"
          onChange={(value: string) => {
            handleAddDate(value);
          }}
          disabled={loading}
        >
          {AddAmountOptions.map(x => (
            <Select.Option key={x.value} value={x.value}>
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </>
  );
}
